<template>
	<div class="detalhapedido">
		<div class="global-closebutton" @click="fechaJanela()">
			X
		</div>
		<div class="global-window-header detalhapedido-header">
			<h1>
				{{ tipoPedido }}
			</h1>
		</div>
		<div class="detalhapedido-info">
			<div class="detalhapedido-infopedido-grupo">
				<h3 class="detalhapedido-infopedido-grupoheader">Pedido</h3>
				<div class="detalhapedido-infopedido pedido-status">
					<label>Status:</label>
					<span>
						{{
							draftIndex != undefined
								? "Rascunho"
								: pedido.id
								? pedido.statuspedido.descricao
								: "Aguardando envio"
						}}
					</span>
				</div>
				<div class="detalhapedido-infopedido">
					<label>Data:</label>
					<span>
						{{ $time(pedido.createdAt).format("DD/MM/YYYY") }}
					</span>
				</div>
				<div class="detalhapedido-infopedido">
					<label>Dav:</label>
					<span>
						{{
							pedido.dav == null || pedido.dav.length == 0
								? "Não preenchido"
								: pedido.dav
						}}
					</span>
				</div>
				<div class="detalhapedido-infopedido">
					<label>Nota:</label>
					<span>
						{{
							pedido.nota == null || pedido.nota.length == 0
								? "Não preenchido"
								: pedido.nota
						}}
					</span>
				</div>
				<div class="detalhapedido-infopedido">
					<label>Nível de Preço:</label>
					<span>
						{{
							draftIndex != undefined ? "Não definido" : pedido.nivpre.descricao
						}}
					</span>
				</div>
				<div class="detalhapedido-infopedido">
					<label>Forma de Pagamento:</label>
					<span>
						{{
							draftIndex != undefined
								? "Não definido"
								: pedido.condpgto.descricao
						}}
					</span>
				</div>
        <div class="detalhapedido-infopedido">
					<label>Ordem de Compra:</label>
					<span>
						{{
							draftIndex != undefined
								? "Não definida"
								: pedido.ordemCompra
						}}
					</span>
				</div>
        <div class="detalhapedido-infopedido">
					<label>Previsão de Entrega:</label>
					<span>
						{{
							draftIndex != undefined
								? "Não definida"
								: $time(pedido.previsao).format("DD/MM/YYYY")
						}}
					</span>
				</div>
				<div class="detalhapedido-infopedido" v-if="mostraValor != 'false'">
					<label>Total do Pedido:</label>
					<span>{{ `${$moeda(pedido.valor_venda)}` }}</span>
				</div>
				<div class="detalhapedido-infopedido">
					<label>Total de Itens:</label>
					<span>{{ `${pedido.produtospedido.length}` }}</span>
				</div>
			</div>
			<div class="detalhapedido-infopedido-grupo">
				<h3 class="detalhapedido-infopedido-grupoheader">Cliente</h3>

				<div class="detalhapedido-infopedido">
					<label>Razão:</label>
					<span>
						{{ `${pedido.cliente.codigo} - ${pedido.cliente.razao}` }}
					</span>
				</div>
				<div class="detalhapedido-infopedido">
					<label>Endereço:</label>
					<span>{{ `${pedido.cliente.ender} ${pedido.cliente.compl}` }}</span>
				</div>
				<div class="detalhapedido-infopedido">
					<label>Bairro:</label>
					<span>{{ `${pedido.cliente.bairro}` }}</span>
				</div>
				<div class="detalhapedido-infopedido">
					<label>Cidade:</label>
					<span>{{ `${pedido.cliente.cidade}` }}</span>
				</div>
				<div class="detalhapedido-infopedido">
					<label>Estado:</label>
					<span>{{ `${pedido.cliente.uf}` }}</span>
				</div>
			</div>
			<div class="detalhapedido-infopedido-botao">
				<button @click="convertePedido()" v-if="draftIndex != undefined">
					Converter para Venda
					<img src="@/assets/icones/sell.svg" alt="whatsapp" />
				</button>
			</div>
			<div class="detalhapedido-infopedido-botao">
				<button @click="enviaPedido()">
					Enviar por Whatsapp
					<img src="@/assets/icones/whatsapp.svg" alt="whatsapp" />
				</button>
			</div>
		</div>
		<div class="detalhapedido-produtos">
			<table class="global-table detalhapedido-table">
				<thead>
					<th>Cod.</th>
					<th>Desc.</th>
					<th>Qnt.</th>
					<template v-if="mostraValor != 'false'">
						<th>Vl. Un</th>
						<th>Subtotal</th>
					</template>
				</thead>
				<tbody v-if="draftIndex == undefined && pedido.id">
					<tr v-for="(produto, key) in pedido.produtospedido" :key="key">
						<td>{{ produto.produto.cod_est }}</td>
						<td>{{ produto.produto.descricao }}</td>
						<td>{{ produto.quant }}</td>
						<template v-if="mostraValor != 'false'">
							<td>{{ $moeda(produto.valor) }}</td>
							<td>{{ $moeda(valorProduto(produto)) }}</td>
						</template>
					</tr>
				</tbody>
				<tbody v-else>
					<tr v-for="(produto, key) in pedido.produtospedido" :key="key">
						<td>{{ produto.cod_est }}</td>
						<td>{{ produto.descricao }}</td>
						<td>{{ produto.quant }}</td>
						<template v-if="mostraValor != 'false'">
							<td>{{ $moeda(produto.valor_vend) }}</td>
							<td>{{ $moeda(valorProduto(produto)) }}</td>
						</template>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex"

export default {
	props: ["pedido", "draftIndex"],

	computed: {
		...mapState(["dadosVenda", "rascunhoPedidos"]),

		User() {
			return JSON.parse(localStorage.user)
		},

		mostraValor() {
			return process.env.VUE_APP_MOSTRA_VALOR
		},

		tipoPedido() {
			let retorno = ""

			if (this.pedido.tVenda !== "V") {
				if (this.pedido.tVenda == "T") {
					retorno = `Troca ${this.pedido.id ? this.pedido.id : "não enviada"}`
				}
				if (this.pedido.tVenda == "B") {
					retorno = `Bonificacao ${
						this.pedido.id ? this.pedido.id : "não enviada"
					}`
				}
			} else {
				retorno =
					this.draftIndex == undefined
						? `Pedido ${this.pedido.id ? this.pedido.id : "não enviado"}`
						: "Rascunho"
			}

			return retorno
		},
	},

	methods: {
		fechaJanela() {
			this.$emit("fecha")
		},

		extend() {
			for (var i = 1; i < arguments.length; i++)
				for (var key in arguments[i])
					if (arguments[i].hasOwnProperty(key))
						arguments[0][key] = arguments[i][key]
			return arguments[0]
		},

		converteData(data) {
			return this.$time(data).format("DD/MM/YYYY")
		},

		valorProduto(produto) {
			if (this.draftIndex != undefined) {
				return produto.valor_vend * produto.quant
			}
			if (!this.draftIndex) {
				if (!this.pedido.id) {
					return produto.valor_vend * produto.quant
				} else {
					return produto.quant * produto.valor
				}
			}
		},

		stringProdPedido() {
			let produtos = ""

			let listaProduto = JSON.parse(JSON.stringify(this.pedido.produtospedido))

			if (listaProduto.length > 70) {
				listaProduto.splice(70, listaProduto.length - 70)
			}

			for (const produto of listaProduto) {
				produtos += `
${String("").padEnd(10, ".")}        
${String(
	this.draftIndex != undefined || !this.pedido.id
		? produto.descricao
		: produto.produto.descricao
).substring(0, 150)}
Qnt.: *${produto.quant}*
`

				this.mostraValor == "true"
					? (produtos += `Valor: *${this.$moeda(this.valorProduto(produto))}*`)
					: ""
			}

			return produtos
		},

		enviaPedido() {
			let buttons = []
			for (const tel of this.pedido.cliente.telfax) {
				buttons.push(
					this.extend({}, this.$vex.dialog.buttons.YES, {
						text: `${tel.nome}: ${tel.tel}`,
						className: "vex-dialog-button-primary btnMarginVex",
						click: function() {
							this.value = tel.tel.replace(/[^\d]/g, "")

							this.close()
						},
					})
				)
			}
			buttons.push(
				this.extend(buttons, this.$vex.dialog.buttons.YES, {
					text: `Outro Número`,
					className: "vex-dialog-button-primary btnMarginVex",
					click: function() {
						this.value = "outro"
						this.close()
					},
				})
			)

			let message = encodeURIComponent(`*${process.env.VUE_APP_NOME_EMPRESA}*

Pedido: *${
				this.draftIndex != undefined
					? "Sem número"
					: this.pedido.id
					? this.pedido.id
					: "Aguardando envio"
			}*
Representante: *${this.User.nome}*
Cliente: *${this.pedido.cliente.codigo} - ${this.pedido.cliente.razao}*
Feito no Dia: *${this.converteData(this.pedido.createdAt)}*
Total do Pedido: *${this.$moeda(this.pedido.valor_venda)}*  
Total de Itens: *${this.pedido.produtospedido.length}*

Itens:${this.stringProdPedido()}`).replace("\t", "")

			this.$vex.dialog.open({
				message: "Selecione o número destino",
				buttons: buttons,
				callback: (response) => {
					if (response) {
						if (response !== "outro") {
							let tel = encodeURI(`+55${response}`)

							window.open(
								`https://api.whatsapp.com/send?phone=${tel}&text=${message}`
							)
						} else {
							this.$vex.dialog.open({
								message: "Digite o número destino",
								input:
									'<input name="tel" type="tel" placeholder="Telefone" required />',
								callback: (res) => {
									if (res) {
										let newTel = res.tel.replace(/[^\d]/g, "")
										window.open(
											`https://api.whatsapp.com/send?phone=+55${newTel}&text=${message}`
										)
									}
								},
							})
						}
					}
				},
			})
		},

		convertePedido() {
			this.$vex.dialog.open({
				message: "Deseja converter este rascunho para venda?",
				callback: (confirm) => {
					if (confirm) {
						if (this.dadosVenda.produtospedido.length > 0) {
							this.$vex.dialog.open({
								message:
									"Existem itens em um pedido pendente, ao converter este rascunho, os itens pendentes serão perdidos, deseja continuar?",
								callback: (confirm1) => {
									if (confirm1) {
										this.$store.state.dadosVenda = JSON.parse(
											JSON.stringify(this.pedido)
										)
										this.rascunhoPedidos.splice(this.draftIndex, 1)
										this.$router.push("/venda")
									}
								},
							})
						} else {
							this.$store.state.dadosVenda = JSON.parse(
								JSON.stringify(this.pedido)
							)
							this.rascunhoPedidos.splice(this.draftIndex, 1)
							this.$router.push("/venda")
						}
					}
				},
			})
		},
	},
}
</script>

<style lang="scss">
.btnMarginVex {
	margin: 5px !important;
}

.detalhapedido {
	height: 100%;
	overflow: auto;
	.detalhapedido-header {
		height: 50px;

		h1 {
			font-size: 30px;
		}
	}
	.detalhapedido-info {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;

		.detalhapedido-infopedido-grupo {
			display: flex;
			flex-wrap: wrap;
			margin: 10px;
			border-bottom: 1px solid #ccc;
			.detalhapedido-infopedido-grupoheader {
				font-weight: lighter;
				font-size: 25px;
				width: 100%;
			}
			.detalhapedido-infopedido {
				margin: 5px 10px;
				// padding: 0 15px 10px 0;
				// border-right: 1px solid #ccc;
				// border-bottom: 1px solid #ccc;
				label {
					display: inline-block;
					font-family: "Lato";
					font-weight: lighter;
					margin-right: 10px;
					font-weight: bold;
				}

				span {
					font-family: "Lato";
					display: inline-block;
				}
			}
		}

		.detalhapedido-infopedido-botao {
			margin: 10px;
			button {
				padding: 10px;
				border: 1px solid #bbb;
				background-color: #ccc;
				cursor: pointer;
				transition: background-color 0.6s ease;
				font-size: 16px;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 250px;
				margin: 0 auto;

				img {
					height: 30px;
					margin-left: 10px;
				}

				&:hover {
					background-color: #aaa;
				}
			}
		}
	}
	.detalhapedido-produtos {
		.detalhapedido-table {
			width: 100%;
		}
	}
}
</style>
