<template>
	<div :class="['global-loading', { visible: visible }]">
		<button @click="fechaLoading">X</button>
		<div class="global-loading-img-msg">
			<h1 v-if="$store.state.loadMessage != ''">
				Carregando {{ $store.state.loadMessage }}
			</h1>
			<img src="@/assets/icones/loading.gif" alt="loading" />
		</div>
		<div class="global-loading-overlay"></div>
	</div>
</template>

<script>
export default {
	computed: {
		visible() {
			return this.$store.state.loading
		},
	},
	methods: {
		fechaLoading() {
			this.$store.state.loading = false
		},
	},
}
</script>

<style lang="scss">
.global-loading {
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: fixed;
	transition: all 0.6s ease;
	z-index: 99;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	opacity: 0;
	visibility: hidden;

	button {
		position: fixed;
		top: 10px;
		left: 10px;
		z-index: 100;
		width: 30px;
		height: 30px;
		font-size: 20px;
	}

	.global-loading-overlay {
		position: absolute;
		height: 100%;
		width: 100%;
		background-color: black;
		opacity: 0.4;
	}

	.global-loading-img-msg {
		z-index: 100;
		background-color: white;
		padding: 15px;
		border-radius: 15px;
		display: flex;
		align-items: center;
		flex-direction: column;
		h1 {
			font-weight: lighter;
		}
		img {
			height: 30px;
			width: 30px;
		}
	}
}

.global-loading.visible {
	visibility: inherit;
	opacity: 1;
}

@media all and (max-width: 800px) {
	.global-loading {
		left: 45px;
		width: calc(100% - 45px);
	}
}

@media all and (max-width: 500px) {
	.global-loading {
		left: 0;
		width: 100%;
	}
}
</style>
