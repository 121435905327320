<template>
	<div class="global-window">
		<SelProduto
			v-if="componentAtual == 'SelProduto'"
			@fechaJanela="componentAtual = ''"
		/>
		<FinVenda
			v-if="componentAtual == 'FinVenda'"
			@fechaJanela="componentAtual = ''"
		/>
		<Header />
		<Table />
		<Footer
			@abreJanela="componentAtual = $event"
			@fechaJanela="componentAtual = ''"
		/>
	</div>
</template>

<script>
import SelProduto from "./components/SelProduto"
import FinVenda from "./components/FinVenda"
import Header from "./components/Header.vue"
import Table from "./components/Table.vue"
import Footer from "./components/Footer.vue"
import { mapState } from "vuex"

export default {
	components: { SelProduto, FinVenda, Header, Table, Footer },

	data() {
		return {
			componentAtual: "",
			dadosVendaDefault: {},
		}
	},

	computed: {
		...mapState(["dadosVenda", "Cache"]),
		mostra() {
			return this.$store.state.mostra
		},
	},

	created() {
		this.setDefault()
		this.retornaDadosVenda()
	},

	methods: {
		abreJanela(param) {
			this.componentAtual = param
		},

		setDefault() {
			this.dadosVendaDefault = {
				valor_venda: 0,
				id_cliente: 0,
				id_condpgto: 1,
				id_nivpre: 1,
				id_statuspedido: 1,
				produtospedido: [],
			}
		},

		retornaDadosVenda() {
			try {
				this.$store.state.dadosVenda = JSON.parse(localStorage.dadosVenda)
				if (this.dadosVenda.cliente == undefined) {
					this.$store.state.mostra = "cliente"
				}
			} catch {
				localStorage.setItem(
					"dadosVenda",
					JSON.stringify(this.dadosVendaDefault)
				)
				this.$store.state.mostra = "cliente"
				this.retornaDadosVenda()
			}
		},
	},
}
</script>
