import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import "./registerServiceWorker"

import "./plugins/global-components"
import "./services/api"
import "./plugins/socket"
import "./plugins/resize"
import "./plugins/online"
import "./plugins/moeda"
import "./plugins/moment"
import "./plugins/maps"
import "./plugins/vex"
import "./classes/Cache"

Vue.config.productionTip = false

Vue.directive("focus", {
	inserted: function(el) {
		el.focus()
	},
})

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app")
