<template>
	<div class="form-div-field">
		<div class="form-field">
			<label>Razao *</label>
			<input
				type="text"
				v-model="Cliente.razao"
				name="razao"
				placeholder="Razão Social"
				:disabled="Cliente.id == undefined ? false : true"
				required
			/>
		</div>
		<div class="form-field">
			<label>Nome Fantasia</label>
			<input
				type="text"
				v-model="Cliente.razao1"
				name="razao1"
				placeholder="Nome Fantasia"
				:disabled="Cliente.id == undefined ? false : true"
			/>
		</div>
		<div class="form-field">
			<label>Grupo</label>
			<select
				:disabled="Cliente.id == undefined ? false : true"
				v-model="Cliente.id_grupo"
			>
				<option
					v-for="(grupo, key) in Cache.GrupoClientes"
					:key="key"
					:value="grupo.id"
				>
					{{ grupo.nome }}
				</option>
			</select>
		</div>
		<div class="form-field">
			<label>CPF / CNPJ *</label>
			<input
				type="number"
				v-model="Cliente.cgccpf"
				name="cgccpf"
				placeholder="CPF ou CNPJ"
				:disabled="Cliente.id == undefined ? false : true"
				required
			/>
		</div>
		<div class="form-field">
			<label>Inscrição Estadual</label>
			<input
				type="text"
				v-model="Cliente.inscrest"
				name="inscrest"
				placeholder="Insc. Estadual"
				:disabled="Cliente.id == undefined ? false : true"
			/>
		</div>
		<div class="form-field">
			<label>Inscrição Municipal</label>
			<input
				type="text"
				v-model="Cliente.inscmun"
				name="inscmun"
				placeholder="Insc. Municipal"
				:disabled="Cliente.id == undefined ? false : true"
			/>
		</div>
		<div class="form-field">
			<label>Email *</label>
			<input
				type="text"
				v-model="Cliente.e_mail"
				name="e_mail"
				placeholder="cliente@provedor.com"
				:disabled="Cliente.id == undefined ? false : true"
				required
			/>
		</div>
		<div class="form-field">
			<label>Observações:</label>
			<textarea
				v-model="Cliente.obs"
				name="obs"
				:disabled="Cliente.id == undefined ? false : true"
			/>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex"
export default {
	props: ["Cliente"],

	computed: {
		...mapState(["Cache"]),
	},
}
</script>

<style lang="scss">
.form-field {
	select {
		option {
			font-size: 18px;
			padding: 10px;
			outline: none;
		}
	}
}
</style>
