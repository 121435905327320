<template>
	<div class="Login">
		<form ref="formLogin" class="login-form">
			<div class="login-header">
				<h1>{{ nomeEmpresa }}</h1>
				<p>Versão: {{ versaoApp }}</p>
			</div>
			<div class="login-body">
				<div class="login-campos">
					<div class="login-campo">
						<label for="login-txtLogin">Usuário:</label>
						<input
							type="text"
							name="login"
							id="login-txtLogin"
							v-model="login"
							ref="txtLogin"
							required
							placeholder="Seu login ou código"
						/>
					</div>
					<div class="login-campo">
						<label for="login-txtSenha">Senha:</label>
						<input
							type="password"
							name="senha"
							id="login-txtSenha"
							v-model="senha"
							required
							placeholder="Sua senha"
						/>
					</div>
				</div>
				<div class="login-icone">
					<img :src="require('@/assets/icones/usuario.png')" alt="" />
				</div>
			</div>
			<div :class="['login-msgLogin', message != '' ? 'active' : '']">
				{{ message }}
			</div>
			<div class="login-button">
				<button @click="formSubmit($event)">Entrar</button>
			</div>
		</form>
	</div>
</template>

<script>
import { mapState } from "vuex"

export default {
	created() {
		this.nomeEmpresa = process.env.VUE_APP_NOME_EMPRESA
	},
	mounted() {
		this.$refs.txtLogin.focus()
		window.addEventListener("keydown", (e) => {
			if (e.key === "Enter") {
				this.formSubmit(e)
			}
		})
	},
	data() {
		return {
			login: "",
			senha: "",
			message: "",
		}
	},

	computed: {
		...mapState(["Cache"]),
		versaoApp() {
			return process.env.PACKAGE_VERSION
		},
	},

	methods: {
		formSubmit(e) {
			e.preventDefault()
			if (this.isOnline) {
				if (this.$refs.formLogin.checkValidity()) {
					const { login, senha } = this
					this.$api
						.post("/login", { login, senha })
						.then((response) => {
							let userData = JSON.stringify(response.data.user)
							localStorage.setItem("token", response.data.token)
							localStorage.setItem("user", userData)
							this.$store.state.usuario = userData
							this.$socket.openConnection()
							this.$reloadCache().then(() => {
								this.$router.push("/")
							})
						})
						.catch((exception) => {
							this.message = exception.response.data.message
						})
				}
			} else {
				this.message = "Internet indisponível"
			}
		},
	},
}
</script>

<style lang="scss">
.Login {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100%;
	min-width: 350px;
	background-color: #e9e9e9;

	.login-form {
		width: 400px;
		max-width: 90%;
		// height: 300px;
		background-color: white;
		box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 5px 0 rgba(0, 0, 0, 0.19);
		position: relative;
		transition: all 0.6s ease;

		.login-header {
			width: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			padding: 2px;
		}
		.login-body {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: row;
			padding: 10px;
			height: 175px;

			.login-campos {
				width: calc(100% - 100px);
				.login-campo {
					input {
						width: 100%;
						font-size: 25px;
						height: 40px;
						padding: 2px;
					}

					label {
						font-family: "roboto";
						font-size: 19px;
						color: #151515;
					}
				}
			}
			.login-icone {
				width: 100px;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 100%;
				}
			}
		}

		.login-msgLogin {
			position: absolute;
			top: 220px;
			height: 25px;
			width: 100%;
			font-family: "Lato";
			font-weight: bold;
			font-size: 15px;
			color: rgb(200, 10, 10);
			visibility: hidden;
			opacity: 0;
			transition: all 0.6s ease;
			text-align: center;
		}
		.active {
			visibility: inherit;
			opacity: 1;
		}

		.login-button {
			height: 100px;
			// position: absolute;
			top: 200px;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;

			button {
				border: none;
				background-color: rgb(180, 180, 180);
				width: 140px;
				height: 60px;
				font-family: "Lato";
				color: #151515;
				font-size: 25px;
				text-align: center;
				box-sizing: border-box;
				box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2),
					0 0px 5px 0 rgba(0, 0, 0, 0.19);
				display: inline-block;
				cursor: pointer;
				transition: all 0.6s ease;

				&:hover {
					background-color: #a1a1a1;
				}
			}
		}
	}
}

@media all and (max-width: 400px) {
	.login-form {
		max-width: 100% !important;
		height: 100%;
	}
}
</style>
