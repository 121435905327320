<template>
	<div class="global-window rota">
		<transition name="mostraForm">
			<FormCliente
				v-if="mostraForm"
				:novo="novo"
				:dadosCliente="curCliente.clienteAtivo"
				@fecha="fechaForm()"
			/>
		</transition>
		<template v-if="Cache.Rota.nome != undefined || User.modo != 2">
			<div class="rota-dados" v-if="User.modo == 2">
				<p>Rota: {{ Cache.Rota.nome }}</p>
				<p>
					{{
						`De ${converteData(
							Cache.Rota.dadosRota.periodo.inicio,
							"DD/MM/YYYY"
						)} a ${converteData(
							Cache.Rota.dadosRota.periodo.fim,
							"DD/MM/YYYY"
						)}`
					}}
				</p>
				<p>
					Próxima data da Rota:
					{{ converteData(Cache.Rota.dadosRota.proximaData, "DD/MM/YYYY") }}
				</p>
			</div>
			<div
				class="rota-body"
				:style="
					User.modo == 2 ? { height: 'calc(100% - 60px)' } : { height: '100%' }
				"
			>
				<vue-draggable-resizable
					:parent="true"
					:handles="['mr']"
					:draggable="false"
					:w="curCliente.mapsize"
					:min-width="150"
					class-name="rota-cliente-resize"
					@resizing="resizeLista"
				>
					<ListaClientes />
					<Menu @abreForm="abreForm($event)" />
				</vue-draggable-resizable>
				<Mapa />
			</div>
		</template>
		<template v-else>
			<h1 style="text-align: center">Sem rota ativa para o Dia</h1>
		</template>
	</div>
</template>

<script>
import { mapState } from "vuex"
import FormCliente from "./components/FormCliente"
import Menu from "./components/Menu"
import Mapa from "./components/Mapa"
import ListaClientes from "./components/ListaClientes"

export default {
	components: { FormCliente, Menu, Mapa, ListaClientes },

	data() {
		return {
			detalha: {},
			mostraForm: false,
			novo: false,
		}
	},

	created() {
		this.dadosVenda.id_nivpre = 1
		this.dadosVenda.id_condpgto = 1

		if (
			this.curCliente.clienteAtivo.id == undefined &&
			this.Cache.Clientes != undefined &&
			this.Cache.Clientes.length > 0
		) {
			this.curCliente.clienteAtivo = this.Cache.Clientes[0]
		}
		// if (this.Cache.Clientes != undefined && this.Cache.Clientes.length > 0) {
		// 	this.curCliente.clienteAtivo = this.
		// }
	},

	mounted() {
		window.addEventListener("mouseup", this.dragStop)
	},

	computed: {
		...mapState(["dadosVenda", "Cache", "curCliente"]),

		User() {
			return JSON.parse(localStorage.user)
		},
	},
	methods: {
		converteData(data, formato = "DD/MM/YYYY - hh:mm") {
			return this.$time(data).format(formato)
		},

		abreForm(param) {
			this.novo = param
			this.mostraForm = true
		},

		fechaForm() {
			this.mostraForm = false
			this.detalha = {}
		},
		resizeLista(x, y, width) {
			this.$store.state.curCliente.mapsize = width
		},
	},
}
</script>

<style lang="scss">
.mostraForm-enter-active,
.mostraForm-leave-active {
	transform: translateX(0);
	visibility: inherit;
	opacity: 1;
	transition: transform 0.6s ease, visibility 0.6s ease, opacity 0.6s ease;
}

.mostraForm-enter,
.mostraForm-leave-to {
	transform: translateX(100%);
	visibility: hidden;
	opacity: 0;
	transition: transform 0.6s ease, visibility 0.6s ease, opacity 0.6s ease;
}

.rota {
	position: relative;
	.rota-dados {
		height: 60px;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		p {
			padding: 5px;
			margin: 0 5px;
			height: 100%;
			width: 100%;
			border-right: 1px solid #ccc;
			display: flex;
			justify-content: center;
			align-items: center;

			&:last-child {
				border-right: none;
			}
		}
	}
	.rota-body {
		display: flex;
		width: 100%;
		height: 100%;
		overflow: hidden;
		border-top: 1px solid #ccc;
		position: relative;

		.rota-cliente-resize {
			height: 100% !important;
			border-right: 1px solid #ccc;
			display: flex;
			z-index: 1 !important;

			.handle-mr {
				height: 25px;
				width: 25px;
				display: flex !important;
				align-items: center;
				justify-content: center;
				background-image: linear-gradient(#fff 0%, rgb(165, 165, 165) 99%);
				border: 1px solid #ccc;
				border-radius: 5px;
				right: -12px;
				transition: all 0.6s ease !important;

				&:active {
					background-image: linear-gradient(rgb(165, 165, 165) 0%, #fff 70%);
				}

				&:after {
					content: "<->";
				}
			}
		}
	}
}
</style>
