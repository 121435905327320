<template>
	<div class="global-window-header formapagamento-header">
		<h1>Forma de Pagamento:</h1>
		<select v-model="dadosVenda.id_condpgto" :disabled="formPagDefinido">
			<option
				v-for="condicao in Cache.CondPgto"
				:key="condicao.id"
				:value="condicao.id"
			>
				{{ condicao.descricao }}
			</option>
		</select>
	</div>
</template>

<script>
import { mapState } from "vuex"
export default {
	created() {
		this.aplicaCondPgto()
	},

	computed: {
		...mapState(["dadosVenda", "Cache"]),

		formPagDefinido() {
			return this.dadosVenda.cliente.id_condpgto != null ? true : false
		},
	},

	methods: {
		aplicaCondPgto() {
			this.$set(
				this.dadosVenda,
				"condpgto",
				this.Cache.CondPgto[this.dadosVenda.id_condpgto - 1]
			)
		},
	},
	watch: {
		CondPgto() {
			this.aplicaCondPgto()
		},
	},
}
</script>

<style lang="scss">
.formapagamento-header {
	text-align: center;
	select {
		display: inline-block;
		margin: 0 auto;
		height: 22px;
		width: 150px;
		margin-top: 4px;
		margin-left: 5px;
		text-align: center;
		font-family: "Lato";
	}
}
</style>
