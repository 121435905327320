import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		//Interface
		loading: false,
		update: true,
		loadMessage: "",
		mostraMenu: false,
		curCliente: {
			clienteAtivo: {},
			index: 0,
			scroll: 0,
			mapsize: 200,
		},
		lastCheck: "",
		usuario: {},

		//Dados da venda
		dadosVenda: {
			valor_venda: 0,
			id_cliente: 0,
			id_condpgto: 1,
			id_nivpre: 1,
			id_statuspedido: 1,
			produtospedido: [],
			previsao: new Date(),
			tVenda: "V",
			ordemCompra: "",
		},
		filaPedidos: [],
		rascunhoPedidos: [],

		//Cache
		Cache: {},
	},

	getters: {
		totalVenda: (state) => {
			let total = state.dadosVenda.produtospedido
				.map((produto) => {
					return produto.vl_promo == 0
						? produto.valor_vend * produto.quant
						: produto.vl_promo * produto.quant
				})
				.reduce((total, atual) => total + atual, 0)

			state.dadosVenda.valor_venda = total
			return total
		},
	},

	mutations: {},

	actions: {},
})
