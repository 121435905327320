<template>
	<div
		class="rota-cliente-mapa"
		:style="{ width: `calc(100% - ${curCliente.mapsize}px)` }"
	>
		<GmapMap
			:center="{
				lat: parseFloat(curCliente.clienteAtivo.lat),
				lng: parseFloat(curCliente.clienteAtivo.lon),
			}"
			:zoom="mapaZoom"
			class="rota-cliente-mapa-gmap"
		>
			<gmap-info-window
				:position="{
					lat: parseFloat(curCliente.clienteAtivo.lat),
					lng: parseFloat(curCliente.clienteAtivo.lon),
				}"
				:opened="mostraInfo"
				@closeclick="mostraInfo = false"
			>
				<h1>{{ curCliente.clienteAtivo.razao }}</h1>
				<h3 v-if="curCliente.clienteAtivo.razao1.length > 0">
					({{ curCliente.clienteAtivo.razao1 }})
				</h3>
				<p>
					{{ curCliente.clienteAtivo.ender }}
					{{ curCliente.clienteAtivo.compl }}
					-
					{{ curCliente.clienteAtivo.cidade }}
					{{ curCliente.clienteAtivo.bairro }}
					{{ curCliente.clienteAtivo.uf }}
				</p>
			</gmap-info-window>
			<GmapMarker
				:key="key"
				v-for="(cliente, key) in Cache.Clientes"
				:position="{
					lat: parseFloat(cliente.lat),
					lng: parseFloat(cliente.lon),
				}"
				:clickable="true"
				:draggable="false"
				@click="alteraCliente(key)"
			/>
		</GmapMap>
	</div>
</template>

<script>
import { mapState } from "vuex"

export default {
	data() {
		return {
			mostraInfo: true,
			mapaZoom: 16,
			buscaCliente: "",
		}
	},

	computed: {
		...mapState(["Cache", "curCliente"]),
	},

	watch: {
		curCliente() {
			this.mostraInfo = true
			this.mapaZoom = 16
		},
	},
}
</script>

<style lang="scss">
.rota-cliente-mapa {
	height: 100%;
	position: absolute;
	right: 0;
	// z-index: ;

	.rota-cliente-mapa-gmap {
		width: 100%;
		height: 100%;

		text-align: center;
		h1 {
			min-width: 50px;
			font-size: 20px;
			font-weight: lighter;
		}

		h3 {
			font-weight: lighter;
			margin: 5px 0;
		}

		p {
			margin-top: 5px;
			span {
				font-weight: bold;
			}
		}
	}
}

.gm-style .gm-style-iw-c {
	opacity: 0.8;
}
</style>
