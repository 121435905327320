<template>
	<div class="global-window pedidos">
		<Detalhapedido
			v-if="mostraDetalhe"
			:pedido="pedidoAtivo"
			@fecha="mostraDetalhe = false"
			:draftIndex="draftIndex"
		/>
		<div v-else>
			<div class="pedidos-header">
				<h1>Pedidos:</h1>
				<div class="pedido-filtro">
					<div class="divCalendar">
						<span>De:</span>
						<datepicker
							:value="dtFin"
							:language="ptBR"
							v-model="dtFin"
							class="calendarioPedidos"
						></datepicker>
					</div>
					<div class="divCalendar">
						<span>Até:</span>
						<datepicker
							:value="dtIni"
							:language="ptBR"
							v-model="dtIni"
							class="calendarioPedidos"
						></datepicker>
					</div>
				</div>
				<button @click.prevent="enviaPedidos()">
					Enviar Pendentes
				</button>
			</div>
			<div class="pedidos-body">
				<table class="global-table pedidos-table">
					<thead>
						<th>Nro.</th>
						<th>Cliente</th>
						<th>Dt. Pedido</th>
						<th v-if="mostraValor != 'false'">Valor</th>
						<th>Status</th>
					</thead>
					<tbody>
						<tr
							v-for="(pedido, key) in rascunhoPedidos"
							:key="key"
							@click="detalhaPedido(pedido, key)"
							class="linhaRascunho"
						>
							<td>{{ tipoPedido(pedido) }}</td>
							<td>Rascunho</td>
							<td align="left">{{ pedido.cliente.razao }}</td>
							<td>Não Enviado</td>
							<td v-if="mostraValor != 'false'">
								{{ $moeda(pedido.valor_venda) }}
							</td>
							<td>
								{{
									`${tipoPedido(pedido)} - ${Cache.StatusPedido[0].descricao}`
								}}
							</td>
						</tr>
					</tbody>
					<tbody>
						<tr
							v-for="(pedido, key) in filaPedidos"
							:key="key"
							@click="detalhaPedido(pedido)"
							class="linhaFilaPedidos"
						>
							<td>S/N</td>
							<td align="left">{{ pedido.cliente.razao }}</td>
							<td>Na Fila</td>
							<td>{{ $moeda(pedido.valor_venda) }}</td>
							<td>
								{{
									`${tipoPedido(pedido)} - ${Cache.StatusPedido[0].descricao}`
								}}
							</td>
						</tr>
					</tbody>
					<tbody>
						<tr
							v-for="(pedido, key) in Cache.Pedidos"
							:key="key"
							@click="detalhaPedido(pedido)"
						>
							<td>{{ pedido.id }}</td>
							<td align="left">
								<p>Cod.: {{ pedido.cliente.codigo }}</p>
								{{ pedido.cliente.razao }}
								<p>{{ pedido.cliente.bairro }}</p>
							</td>
							<td>{{ converteData(pedido.createdAt) }}</td>
							<td v-if="mostraValor != 'false'">
								{{ $moeda(pedido.valor_venda) }}
							</td>
							<td>
								{{ `${tipoPedido(pedido)} - ${pedido.statuspedido.descricao}` }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import Detalhapedido from "./components/Detalhapedido.vue"
import Datepicker from "vuejs-datepicker"
import { ptBR } from "vuejs-datepicker/dist/locale"

import { mapState } from "vuex"

export default {
	components: {
		Detalhapedido,
		Datepicker,
	},

	data() {
		return {
			mostraDetalhe: false,
			pedidoAtivo: {},
			draftIndex: null,
			ptBR: ptBR,
			dtIni: new Date(),
			dtFin: new Date(),
		}
	},

	created() {
		this.dtFin.setDate(this.dtFin.getDate() - 7)
		if (this.isOnline) {
			this.filtraPedidos()
		}
	},

	computed: {
		...mapState(["Cache", "filaPedidos", "rascunhoPedidos"]),
		mostraValor() {
			return process.env.VUE_APP_MOSTRA_VALOR
		},
	},

	methods: {
		converteData(data) {
			return this.$time(data).format("DD/MM/YYYY")
		},

		filtraPedidos() {
			let objPost = {
				dtini: this.dtIni,
				dtfin: this.dtFin,
			}

			this.$api.post("/pedidos/vendedor", objPost).then((res) => {
				this.$store.state.Cache.Pedidos = res.data
			})
		},

		async enviaPedidos() {
			if (this.isOnline) {
				if (this.filaPedidos.length > 0) {
					await this.$reloadCache("PedidosPendentes")
					await this.$reloadCache("RotaUsuario")
				} else {
					this.$vex.dialog.alert("Não existem pedidos pendentes")
				}
			} else {
				this.$vex.dialog.alert("Não é possível enviar pedidos em modo offline")
			}
		},

		detalhaPedido(pedido, key) {
			this.pedidoAtivo = pedido
			this.draftIndex = key
			this.mostraDetalhe = true
		},

		tipoPedido(pedido) {
			let retorno = "Venda"

			if (pedido.tVenda == "B") {
				retorno = "Bonificação"
			}

			if (pedido.tVenda == "T") {
				retorno = "Troca"
			}

			return retorno
		},
	},

	watch: {
		dtIni(after, before) {
			if (!this.isOnline) {
				this.dtIni = before
			} else {
				this.filtraPedidos()
			}
		},
		dtFin(after, before) {
			if (!this.isOnline) {
				this.dtFin = before
			} else {
				this.filtraPedidos()
			}
		},
	},
}
</script>

<style lang="scss">
.linhaRascunho {
	background-color: rgb(121, 121, 121);
	color: white;
}

.linhaFilaPedidos {
	background-color: rgb(240, 248, 79) !important;
}

.pedidos {
	height: 100%;
}

.calendarioPedidos {
	display: inline-block;
	input {
		width: 80px;
	}
}

.pedidos-header {
	width: 100%;
	height: 80px;
	min-width: 220px;
	position: absolute;
	top: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.divCalendar {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		margin-right: 5px;
	}

	h1 {
		text-align: center;
		font-family: "Lato";
		font-weight: lighter;
		width: 100%;
	}

	button {
		height: 40px;
	}
}

.pedidos-body {
	overflow: auto;
	position: absolute;
	top: 80px;
	max-height: calc(100% - 80px);
	width: 100%;
	.pedidos-table {
		border-bottom: none !important;
		height: 100%;
		width: 100%;

		tbody {
			tr {
				text-align: center;
				td {
					padding: 10px 0;
					font-size: 15px;
					p {
						font-weight: bold;
						font-size: 13px;
					}
				}
			}
			tr:hover {
				background-color: rgb(189, 189, 189) !important;
				cursor: pointer;
			}
		}
	}
}
</style>
