import reloadCache from "@/classes/Cache"
import Store from "@/store/index"

const SocketList = (socket) => {
	return {
		connect: socket.io.on("connect", () => {
			// console.log("Conectado ao Socket")
		}),

		checkRota: socket.io.on("checkRota", () => {
			if (Store.state.lastCheck == "") {
				Store.state.lastCheck = new Date().getDate()
			}

			if (Store.state.lastCheck != new Date().getDate()) {
				Store.state.lastCheck = new Date().getDate()
				reloadCache("RotaUsuario")
			}
		}),

		updatedRotaUsuario: socket.io.on("updatedRotaUsuario", () => {
			reloadCache("RotaUsuario")
		}),

		All: socket.io.on("All", () => {
			reloadCache()
		}),

		updatedLogRota: socket.io.on("updatedLogRota", () => {
			reloadCache("RotaUsuario")
		}),

		updatedClientesRota: socket.io.on("updatedClientesRota", () => {
			reloadCache("RotaUsuario")
		}),

		updatedRota: socket.io.on("updatedRota", () => {
			reloadCache("RotaUsuario")
		}),

		updatedTelfax: socket.io.on("updatedTelfax", () => {
			reloadCache("RotaUsuario")
		}),

		updatedClientes: socket.io.on("updatedClientes", () => {
			reloadCache("RotaUsuario")
		}),

		updatedProduto: socket.io.on("updatedProduto", () => {
			reloadCache("Produtos")
		}),

		updatedPedido: socket.io.on("updatedPedido", () => {
			reloadCache("Pedidos")
		}),

		updatedUsuariosNotificacao: socket.io.on(
			"updatedUsuariosNotificacao",
			() => {
				reloadCache("Notificacoes")
			}
		),
	}
}

export default SocketList
