<template>
	<div class="FormCliente">
		<div class="global-closebutton fecha" @click="$emit('fecha')">
			X
		</div>
		<div class="form-topMenu">
			<ul>
				<li>
					<button
						@click.prevent="formAtivo = 1"
						:style="formAtivo === 1 ? { backgroundColor: '#ccc' } : {}"
					>
						Básico
					</button>
				</li>
				<li>
					<button
						@click.prevent="formAtivo = 2"
						:style="formAtivo === 2 ? { backgroundColor: '#ccc' } : {}"
					>
						Endereço
					</button>
				</li>
				<li>
					<button
						@click.prevent="formAtivo = 3"
						:style="formAtivo === 3 ? { backgroundColor: '#ccc' } : {}"
					>
						Telefones
					</button>
				</li>
			</ul>
		</div>
		<form ref="formCadastroCliente" @invalid="validaForm($event)">
			<div class="form-body">
				<Basico :Cliente="Cliente" v-show="formAtivo === 1" />
				<Endereco :Cliente="Cliente" v-show="formAtivo === 2" />
				<Telefone
					:Cliente="Cliente"
					v-show="formAtivo === 3"
					:tempTel="tempTel"
				/>
			</div>
			<div class="form-submit">
				<button
					:disabled="Cliente.id == undefined ? false : true"
					type="submit"
					@click="cadastraCliente($event)"
				>
					Enviar
				</button>
			</div>
		</form>
	</div>
</template>

<script>
import Basico from "./formClienteComponents/Basico"
import Endereco from "./formClienteComponents/Endereco"
import Telefone from "./formClienteComponents/Telefone"

export default {
	props: ["dadosCliente", "novo"],

	components: { Basico, Endereco, Telefone },

	created() {
		if (!this.novo) {
			this.Cliente = this.dadosCliente
		}
	},

	data() {
		return {
			formAtivo: 1,
			tempTel: [],
			Cliente: {
				razao: "",
				razao1: "",
				cgccpf: "",
				inscrest: "",
				inscmun: "",
				e_mail: "",
				cep: "",
				ender: "",
				compl: "",
				bairro: "",
				cidade: "",
				uf: "",
				obs: "",
				id_status: 2,
				id_grupo: 1,
			},
		}
	},

	methods: {
		cadastraCliente(e) {
			if (this.isOnline) {
				if (this.$refs.formCadastroCliente.checkValidity()) {
					e.preventDefault()
					this.Cliente.telfax = this.tempTel
					this.$api.post("/clientes", this.Cliente).then(() => {
						this.$vex.dialog.alert(
							"Cliente cadastrado, aguarde liberação da matriz para poder vender"
						)
						this.$emit("fecha")
					})
				}
			} else {
				this.$vex.dialog.alert("Internet indisponível")
			}
		},
	},
}
</script>

<style lang="scss">
.FormCliente {
	width: 100%;
	height: 100%;
	z-index: 2;
	position: absolute;
	background-color: white;

	.fecha {
		right: 2px !important;
		left: inherit;
	}

	.form-topMenu {
		height: 50px;
		width: 100%;
		ul {
			height: 100%;
			list-style-type: none;
			display: flex;
			border-bottom: 1px solid #ccc;
			li {
				button {
					padding: 0 15px;
					height: 100%;
					border: none;
					border-left: 1px solid #ccc;
					background-color: #fff;
					transition: background-color ease 0.6s;
					cursor: pointer;
				}

				&:first-child {
					margin-left: 0;
					border-left: none;
				}
			}
		}
	}

	form {
		height: calc(100% - 50px);
		display: flex;
		flex-direction: column;
		align-items: center;

		.form-body {
			height: calc(100% - 80px);
			overflow: auto;
			width: 100%;
			.form-div-field {
				width: 100%;
				margin: 0 auto;
				padding: 10px;
				.form-field {
					width: 100%;
					margin: 15px auto;
					label {
						display: block;
					}
					input[type="text"],
					input[type="number"] {
						width: 100%;
						padding: 5px;
						border: 1px solid rgb(146, 146, 146);
					}
					textarea {
						width: 100%;
						resize: none;
						height: 100px;
					}
					select {
						padding: 5px;
						width: 100%;
						border: 1px solid rgb(146, 146, 146);
					}
				}
			}
		}
		.form-submit {
			width: 100%;
			height: 80px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-top: 1px solid #ccc;
			button {
				height: 40px;
				width: 60%;
			}
		}
	}
}

@media all and (min-width: 600px) {
	.form-div-field {
		max-width: 50%;
	}
}
</style>
