import Vue from "vue"
import io from "socket.io-client"
import SocketList from "@/classes/SocketList"

const socket = {
	openConnection: () => {
		let opt = {}

		if (localStorage.user != undefined) {
			const { id, codigo } = JSON.parse(localStorage.user)
			opt = {
				query: { id, codigo, versao: process.env.PACKAGE_VERSION },
			}
		}

		socket.io = new io(process.env.VUE_APP_BASE_URL, opt)

		SocketList(socket)
	},

	closeConnection: () => {
		socket.io.close()
	},

	io: {},
}

Vue.use({
	install(Vue) {
		Vue.prototype.$socket = socket
	},
})
