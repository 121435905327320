<template>
	<div class="global-window venda-sel">
		<div>
			<div class="global-closebutton" @click="fechaJanela()">
				X
			</div>
			<div class="global-window-header venda-sel-header">
				<h1>Selecione o Produto</h1>
				<div class="venda-sel-subheader">
					<div class="venda-sel-selectGrupo">
						<select v-model="curGrupo">
							<option
								:value="grupo.id"
								v-for="(grupo, key) in Cache.Produtos"
								:key="key"
							>
								{{ grupo.nome }}
							</option>
							<option value="0">TODOS</option>
						</select>
					</div>
					<div class="venda-sel-busca">
						<input
							type="text"
							v-model="Busca"
							class="input-produto"
							placeholder="Busca do Produto"
							@input="isTyping = true"
						/>
						<button @click="addProdutos()">
							<img src="@/assets/icones/tick.svg" alt="" />
						</button>
					</div>
				</div>
			</div>
			<div class="venda-sel-body">
				<table class="global-table venda-sel-table">
					<thead>
						<th>Descrição</th>
						<th v-if="mostraValor != 'false'">Vl. Venda</th>
						<th>Quant.</th>
						<th>Código</th>
					</thead>
					<tbody>
						<template v-if="listaAtual.length > 0">
							<tr v-for="(produto, key) in listaAtual" :key="key">
								<td>
									<p>
										{{ produto.descricao }}
									</p>
									<img
										class="venda-sel-promoicon"
										v-if="produto.vl_promo > 0"
										src="@/assets/icones/promo.svg"
									/>
								</td>
								<td align="right" v-if="mostraValor != 'false'">
									<p>
										{{ vlProduto(produto, Produtos[produto.key].quant) }}
									</p>
								</td>
								<td align="center" width="60px">
									<input
										type="number"
										v-model="Produtos[produto.key].quant"
										placeholder="0"
									/>
								</td>
								<td align="left">
									<p>
										{{ produto.cod_est }}
									</p>
								</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex"
import _ from "lodash"

export default {
	data() {
		return {
			Busca: "",
			Produtos: [],
			listaAtual: [],
			produtosAdd: [],
			tipoBusca: 0,
			curGrupo: 1,
			isTyping: false,
		}
	},

	computed: {
		...mapState(["dadosVenda", "mostraSelProduto", "Cache"]),
		mostraValor() {
			return process.env.VUE_APP_MOSTRA_VALOR
		},

		vendeSemProduto() {
			return process.env.VUE_APP_VENDE_SEM_PRODUTO
		},

		produtosCliente() {
			return this.Cache.ProdutosCliente.filter((prod) => {
				return prod.id_cliente == this.dadosVenda.cliente.id
			})
		},
	},

	created() {
		this.defineProdutos()
		this.filtraProdutosGrupo()
	},

	methods: {
		fechaJanela() {
			this.$emit("fechaJanela")
		},

		defineProdutos() {
			this.Cache.Produtos.forEach((grupo) => {
				this.Produtos = this.Produtos.concat(grupo.produtos)
			})

			if (
				this.produtosCliente[0] != undefined &&
				this.produtosCliente[0].produtos.length > 0
			) {
				this.Produtos = this.Produtos.filter((produtoCompleto) =>
					this.produtosCliente[0].produtos.find((prod) => {
						if (prod.id_produto == produtoCompleto.id) {
							produtoCompleto.valor_vend = prod.valor_vend
							return produtoCompleto
						}
					})
				)
			} else {
				if (this.vendeSemProduto == "false") {
					this.Produtos = []
				}
			}

			this.Produtos.forEach((produto, key) => {
				this.$set(produto, "key", key)
				this.$set(produto, "quant", "")
			})
		},

		filtraProdutosGrupo() {
			if (this.curGrupo == 0) {
				this.listaAtual = this.Produtos
			} else {
				this.listaAtual = this.Produtos.filter(
					(produto) => produto.id_grupo == this.curGrupo
				)
			}
			this.Busca = ""
		},

		buscaProduto() {
			if (this.Busca != "") {
				let filtros = [
					(produto) =>
						produto.descricao.indexOf(this.Busca.toUpperCase()) != -1 ||
						produto.cod_est.startsWith(this.Busca.toUpperCase()),
				]
				this.listaAtual = this.Produtos.filter(filtros[this.tipoBusca])
			} else {
				this.filtraProdutosGrupo()
			}
		},

		fatorReal(produto) {
			let fator = this.listaAtual
				.filter((value) => value.id_produto == produto.id)
				.map((value) => value.fator)[0]

			return fator > 0 ? fator : produto.fator
		},

		addProdutos() {
			let produtosAdd = []
			this.Produtos.forEach((produto) => {
				if (!Number.isNaN(produto.quant) && produto.quant != 0) {
					produtosAdd.push(JSON.parse(JSON.stringify(produto)))
				}
			})

			if (produtosAdd.length > 0) {
				if (process.env.VUE_APP_MULTI_ITENS != "false") {
					this.dadosVenda.produtospedido = this.dadosVenda.produtospedido.concat(
						JSON.parse(JSON.stringify(produtosAdd))
					)
				} else {
					let notValid = false

					for (const produto of produtosAdd) {
						if (
							produto.quant % this.fatorReal(produto) != 0 &&
							this.dadosVenda.tVenda == "V"
						) {
							notValid = true
							this.$vex.dialog.alert({
								message: `Quantidade ${produto.quant} para o produto ${
									produto.descricao
								} é inválida (Não é múltiplo de ${this.fatorReal(produto)})`,
							})

							let id_prod = this.Produtos.findIndex(
								(prod) => prod.id == produto.id
							)

							this.Produtos[id_prod].quant = this.multiploProximo(
								this.fatorReal(produto),
								produto.quant,
								false
							)
							break
						}
					}

					if (!notValid) {
						for (const produto of produtosAdd) {
							let consulta = this.dadosVenda.produtospedido.findIndex(
								(prod) => prod.id == produto.id
							)

							if (consulta != -1) {
								this.dadosVenda.produtospedido[consulta].quant =
									Number(this.dadosVenda.produtospedido[consulta].quant) +
									Number(produto.quant)
							} else {
								this.dadosVenda.produtospedido = this.dadosVenda.produtospedido.concat(
									JSON.parse(JSON.stringify(produto))
								)
							}
						}

						this.fechaJanela()
					}
				}
			}
		},

		vlProduto(produto, quant) {
			let valor = produto.valor_vend

			if (produto.vl_promo > 0) {
				valor = produto.vl_promo
			}

			if (quant > 0) {
				valor = valor * quant
			}
			return this.$moeda(valor)
		},

		multiploProximo(fator, valor, maior = true) {
			let valAtual = valor
			while (valAtual % fator != 0) {
				if (maior) {
					valAtual++
				} else {
					valAtual--
				}
			}
			if (valAtual == 0) {
				return this.multiploProximo(fator, valor)
			}
			return valAtual
		},
	},

	watch: {
		Busca: _.debounce(function() {
			this.isTyping = false
		}, 1000),
		isTyping: function(value) {
			if (!value) {
				this.buscaProduto()
			}
		},
		curGrupo() {
			this.filtraProdutosGrupo()
		},
	},
}
</script>

<style lang="scss">
.venda-sel {
	position: absolute !important;
	z-index: 2;
	margin: 0 auto;
	left: 0;
	right: 0;
	display: inline-block;
	width: 100% !important;
	height: 100% !important;
	.venda-sel-header {
		position: absolute;
		background-color: white;

		.venda-sel-subheader {
			width: 90%;
			margin: 0 auto;
			.venda-sel-selectGrupo {
				height: 30px;
				margin-bottom: 10px;
				select {
					width: 100%;
					height: 100%;
				}
			}
			.venda-sel-busca {
				display: flex;
				.input-produto {
					width: calc(100% - 50px);
					margin-right: 10px;
					display: inline-block;
					font-family: "Lato";
					font-weight: lighter;
					height: 40px;
					padding: 5px;
					font-size: 20px;
				}
			}

			button {
				height: 40px;
				width: 40px;
				background-color: inherit;
				border: none;
				padding: 5px;
				transition: background-color 0.6s ease;
				cursor: pointer;
				&:hover {
					background-color: #ccc;
				}
				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	.venda-sel-body {
		position: absolute;
		width: 100%;
		overflow: auto;
		top: 130px !important;
		height: calc(100% - 130px) !important;

		.venda-sel-table {
			width: 100%;
			tr {
				cursor: pointer;
				&:hover {
					background-color: rgb(197, 197, 197);
				}

				td {
					height: 25px;
					p {
						height: 100%;
						display: inline-block;
						font-size: 18px;
					}
					.venda-sel-promoicon {
						height: 100%;
						margin-left: 5px;
						display: inline-flex;
						justify-content: center;
						align-items: center;
					}
					input {
						margin: 0;
						width: 100%;
						text-align: center;
					}
				}
			}
		}
	}
}
</style>
