<template>
	<div class="venda-carrinho-footer">
		<div
			class="global-lefticonbutton venda-carrinho-footer-button"
			@click="abreJanela('SelProduto')"
		>
			<div>+</div>
			<h1>Adicionar Produto</h1>
		</div>

		<div class="venda-carrinho-footer-total">
			<p>Itens: {{ dadosVenda.produtospedido.length }}</p>
			<p v-if="mostraValor != 'false'">Total: {{ $moeda(totalVenda) }}</p>
		</div>

		<div
			class="global-righticonbutton venda-carrinho-footer-button venda-carrinho-footer-rightbutton"
			@click="finalizaVenda()"
		>
			<h1>Finalizar Venda</h1>
			<div>
				<img :src="require(`@/assets/icones/compra.svg`)" alt="icone" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
export default {
	computed: {
		...mapState(["dadosVenda"]),
		...mapGetters({
			totalVenda: "totalVenda",
		}),
		mostraValor() {
			return process.env.VUE_APP_MOSTRA_VALOR
		},
	},

	methods: {
		abreJanela(param) {
			this.$emit("abreJanela", param)
		},

		finalizaVenda() {
			if (this.dadosVenda.produtospedido.length == 0) {
				this.$vex.dialog.alert("A venda não pode ser finalizada sem produtos")
			} else {
				let ok = 0
				for (var produto of this.dadosVenda.produtospedido) {
					if (produto.quant != 0) {
						ok++
					}
				}
				if (ok == this.dadosVenda.produtospedido.length) {
					this.abreJanela("FinVenda")
				} else {
					this.$vex.dialog.alert("Existem produtos com quantidade inválida")
				}
			}
		},
	},
}
</script>

<style lang="scss">
.venda-carrinho-footer {
	position: absolute;
	bottom: 0;
	height: 100px;
	width: 100%;
	padding: 0 10px 0 10px;

	.venda-carrinho-footer-total {
		position: absolute;
		width: 160px;
		height: 40px;
		background-color: #a5a5a5;
		margin: 0 auto;
		left: 0;
		right: 0;
		top: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		font-family: "Lato";
	}
	.venda-carrinho-footer-button {
		margin-top: 45px;

		h1 {
			background-color: #a5a5a5;
		}
		&:hover {
			background-color: #a5a5a5 !important;
		}
	}

	.venda-carrinho-footer-rightbutton {
		float: right;
	}
}

@media all and (max-width: 500px) {
	.venda-carrinho-footer-button {
		width: 40px !important;

		h1 {
			visibility: hidden;
			opacity: 0;
		}
	}
}
</style>
