<template>
	<div class="global-window" id="Usuario">
		<div class="global-window-header">
			<h1>Cadastro do Usuário</h1>
		</div>
		<hr />
		<div class="global-window-body"></div>
	</div>
</template>

<script>
export default {}
</script>

<style lang="scss"></style>
