<template>
	<div class="venda-resumovenda">
		<div class="venda-resumovenda-left">
			<table class="global-table venda-resumovenda-table">
				<thead>
					<tr>
						<th>Cod.</th>
						<th>Desc.</th>
						<th>Qnt</th>
						<th v-if="mostraValor != 'false'">Vl</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(produto, key) in dadosVenda.produtospedido" :key="key">
						<td>{{ produto.cod_est }}</td>
						<td>{{ produto.descricao }}</td>
						<td>{{ produto.quant }}</td>
						<td v-if="mostraValor != 'false'">
							{{ vlProduto(produto, produto.quant) }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="venda-resumovenda-right">
			<div class="venda-resumovenda-data">
				<h1 class="label">Nível de Preço:</h1>
				<h1>{{ dadosVenda.nivpre.descricao }}</h1>
			</div>
			<div class="venda-resumovenda-data">
				<template v-if="mostraValor != 'false'">
					<h1 class="label">Total:</h1>
					<h1>{{ $moeda(totalVenda) }}</h1>
				</template>
			</div>
			<div class="venda-resumovenda-obs">
				<h1 class="label">Observação:</h1>
				<textarea v-model="dadosVenda.obs"></textarea>
			</div>
		</div>
		<div class="venda-resumovenda-buttons">
			<div
				class="global-lefticonbutton venda-resumovenda-rascunho"
				@click="gravaRascunho()"
			>
				<div>
					<img :src="require(`@/assets/icones/draft.svg`)" alt="icon" />
				</div>
				<h1>Gravar Rascunho</h1>
			</div>
			<div
				class="global-righticonbutton venda-resumovenda-finalizar"
				@click="finalizaVenda()"
			>
				<h1>Finalizar Venda</h1>
				<div>
					<img :src="require(`@/assets/icones/compra.svg`)" alt="icon" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
export default {
	data() {
		return {
			dadosVendaDefault: {},
		}
	},
	computed: {
		...mapState(["dadosVenda", "Cache", "filaPedidos", "rascunhoPedidos"]),
		...mapGetters({
			totalVenda: "totalVenda",
		}),
		mostraValor() {
			return process.env.VUE_APP_MOSTRA_VALOR
		},
	},
	created() {
		this.setDefault()
	},
	methods: {
		setDefault() {
			this.dadosVendaDefault = {
				valor_venda: 0,
				id_cliente: 0,
				id_condpgto: 1,
				id_nivpre: 1,
				id_statuspedido: 1,
				produtospedido: [],
			}
		},

		vlProduto(produto, quant = 0) {
			let valor = produto.valor_vend

			if (produto.vl_promo > 0) {
				valor = produto.vl_promo
			}

			if (quant > 0) {
				valor = valor * quant
			}
			return this.$moeda(valor)
		},

		limpaVenda() {
			this.$store.state.mostra = undefined
			this.$set(this.$store.state, "dadosVenda", this.dadosVendaDefault)
		},
		gravaRascunho() {
			this.dadosVenda.valor_venda = this.totalVenda
			this.rascunhoPedidos.push(JSON.parse(JSON.stringify(this.dadosVenda)))
			this.$vex.dialog.alert("Rascunho gravado com sucesso!")
			this.limpaVenda()
			this.$router.push("/")
		},
		finalizaVenda() {
			this.$vex.dialog.confirm({
				message: "Deseja mesmo finalizar esta venda?",

				callback: async (response) => {
					if (response) {
						this.dadosVenda.valor_venda = this.totalVenda
						if (this.isOnline) {
							this.$api
								.post("/vendas", this.dadosVenda)
								.then((resp) => {
									let user = JSON.parse(localStorage.user)

									let objVisita = {
										id_rota: this.Cache.Rota.id,
										id_cliente: this.dadosVenda.cliente.id,
										id_usuario: user.id,
										visitedAt: Date.now(),
										obs: "VENDA EFETUADA",
									}

									this.$api.post("/logrota", objVisita).then(() => {
										this.$reloadCache("RotaUsuario")
									})

									this.$vex.dialog.alert(
										"O pedido " + resp.data.id + " foi enviado com sucesso!"
									)
									this.limpaVenda()
									this.$reloadCache("Pedidos").then(this.$router.push("/"))
								})
								.catch(() => {
									this.$vex.dialog.alert("Não foi possível gravar o pedido")
								})
						} else {
							this.filaPedidos.push(JSON.parse(JSON.stringify(this.dadosVenda)))
							this.$vex.dialog.alert(
								"Pedido gravado na fila de envio com sucesso!"
							)
							this.limpaVenda()
							this.$router.push("/")
						}
					}
				},
			})
		},
	},
}
</script>

<style lang="scss">
.venda-resumovenda {
	width: 100%;
	// height: calc(100% - 120px);
	overflow: auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.venda-resumovenda-left {
		min-width: 200px;
		width: 45%;
		max-height: 280px;
		display: inline-block;
		margin: 15px;
		overflow: auto;

		.venda-resumovenda-table {
			width: 100%;
		}
	}

	.venda-resumovenda-right {
		min-width: 200px;
		width: 45%;
		height: 200px;
		min-height: 200px;
		display: inline-block;

		.venda-resumovenda-data {
			text-align: center;
			h1 {
				display: inline-block;
				font-family: "Lato";
				font-weight: lighter;
				font-size: 1.2em;
				margin: 0 5px;
			}
			.label {
				font-size: 0.9em;
			}
		}

		.venda-resumovenda-obs {
			width: 100%;
			margin: 20px auto;

			.label {
				font-size: 20px;
				font-weight: lighter;
				margin-left: 20px;
			}

			textarea {
				display: block;
				margin: 0 auto;
				width: 90%;
				height: 100px;
				resize: none;
			}
		}
	}

	.venda-resumovenda-buttons {
		width: 100%;
		max-width: 500px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		padding: 15px;

		.venda-resumovenda-rascunho {
			margin: 10px;
		}

		.venda-resumovenda-finalizar {
			margin: 10px;
			h1 {
				background-color: #27ad15;
			}
			div {
				&:hover {
					background-color: #27ad15;
				}
			}
		}
	}

	@media all and (max-width: 850px) {
		.venda-resumovenda-left {
			width: 100%;
		}
		.venda-resumovenda-right {
			width: 100%;
		}
		.venda-resumovenda-data {
			width: 100%;
		}
		.venda-resumovenda-buttons {
			justify-content: center;
		}
	}
}
</style>
