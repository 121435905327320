import Vue from "vue"
import Router from "vue-router"
import Store from "../store/index.js"

import Login from "../views/Login/Login.vue"
import Venda from "../views/Venda/Venda.vue"
import Pedidos from "../views/Pedidos/Pedidos.vue"
import Clientes from "../views/Clientes/Clientes.vue"
import Notificacoes from "../views/Notificacoes/Notificacoes.vue"
import Produtos from "../views/Produtos/Produtos.vue"
import ResumoVendas from "../views/ResumoVendas/ResumoVendas.vue"
import Usuario from "../views/Usuario/Usuario.vue"

Vue.use(Router)

const router = new Router({
	mode: "history",
	base: process.env.BASE_URL,
	routes: [
		{
			path: "/login",
			name: "login",
			component: Login,
			props: true,
			children: [
				{
					path: ":parametro",
					component: Login,
					props: true,
				},
			],
		},

		{ path: "/", name: "home", component: Clientes },
		{ path: "/venda", name: "venda", component: Venda },
		{ path: "/pedidos", name: "pedidos", component: Pedidos },
		{ path: "/clientes", name: "clientes", component: Clientes },
		{ path: "/notificacoes", name: "notificacoes", component: Notificacoes },
		{ path: "/produtos", name: "produtos", component: Produtos },
		{ path: "/resumovendas", name: "resumovendas", component: ResumoVendas },
		{ path: "/usuario", name: "usuario", component: Usuario },
	],
})

router.beforeResolve((to, from, next) => {
	// If this isn't an initial page load.
	if (to.name) {
		Store.state.loading = true
	}
	next()
})

router.afterEach(() => {
	Store.state.loading = false
})

export default router
