<template>
	<div
		class="home-layout-usermenu"
		:style="
			!isOnline || totalNotificacoes > 0
				? { width: '65px', minWidth: '65px' }
				: { width: '45px', minWidth: '45px' }
		"
		@click="mostraMenu = !mostraMenu"
	>
		<h1 class="home-layout-usermenu-username">{{ User.nome.split(" ")[0] }}</h1>
		<transition name="estendeMenu">
			<div class="home-layout-usermenu-internet" v-if="!isOnline">
				<img src="@/assets/icones/offline.png" />
			</div>
			<div
				class="home-layout-usermenu-usernotifications"
				v-if="totalNotificacoes > 0"
			>
				{{ totalNotificacoes }}
			</div>
		</transition>
		<div
			class="home-layout-usermenu-userpic"
			:style="!isOnline || totalNotificacoes > 0 ? { right: '10px' } : {}"
		>
			<img
				:src="
					User.foto == null
						? require('@/assets/icones/usuario.png')
						: User.urlFoto
				"
				alt="usuario"
			/>
		</div>
		<div :class="['home-layout-usermenu-usermenu', { visible: mostraMenu }]">
			<ul>
				<template v-for="(menu, index) in Menu">
					<li :key="index" v-if="menu.nav == 1">
						<router-link :to="menu.funcao">
							<h1>{{ menu.nome }}</h1>
							<div>
								<img :src="menu.urlIcone" alt="icone" />
							</div>
						</router-link>
					</li>
				</template>
			</ul>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex"

export default {
	data() {
		return {
			mostraMenu: false,
		}
	},
	computed: {
		...mapState(["Cache"]),
		User() {
			return JSON.parse(localStorage.user)
		},
		Menu() {
			return this.$store.state.Cache.Menus
		},
		totalNotificacoes() {
			let i = 0
			this.Cache.Notificacoes.forEach((notificacao) => {
				if (notificacao.status == 0) {
					i++
				}
			})
			return i
		},
	},
}
</script>

<style lang="scss">
.estendeMenu-enter-active,
.estendeMenu-leave-active {
	transform: translateX(0);
	visibility: inherit;
	opacity: 1;
	transition: transform 0.6s ease, visibility 0.6s ease, opacity 0.6s ease;
}

.estendeMenu-enter,
.estendeMenu-leave-to {
	transform: translateX(100%);
	visibility: hidden;
	opacity: 0;
	transition: transform 0.6s ease, visibility 0.6s ease, opacity 0.6s ease;
}

.home-layout-usermenu {
	background-color: white;
	height: 45px;
	z-index: 2;
	transition: all 0.6s ease;
	cursor: pointer;

	.home-layout-usermenu-internet {
		position: absolute;
		top: 0;
		right: 0;
		height: 20px;
		width: 20px;
		z-index: 3;
		img {
			height: 100%;
			width: 100%;
		}
	}

	.home-layout-usermenu-usernotifications {
		position: absolute;
		right: 0;
		background-color: #c73232;
		color: white;
		font-size: 15px;
		z-index: 3;
		min-width: 16px;
		height: 20px;
		top: 25px;
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		font-family: "Open Sans";
	}

	.home-layout-usermenu-username {
		font-family: "Lato";
		z-index: 2;
		background-color: white;
		width: calc(100% - 45px);
		height: 45px;
		// left: 0;
		font-weight: normal;
		font-size: 35px;
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		opacity: 0;
		visibility: hidden;
		transition: all 0.6s ease;
	}

	.home-layout-usermenu-userpic {
		background-color: white;
		width: 45px;
		height: 45px;
		float: right;
		position: relative;
		right: 0;
		transition: all 0.6s ease;

		img {
			height: 100%;
			width: 100%;
		}
	}

	.home-layout-usermenu-usermenu {
		position: fixed;
		top: 45px;
		right: 0;
		width: 200px;
		background-color: white;
		z-index: -1;
		transform: translateY(-100%);
		width: 200px;
		opacity: 0;
		visibility: hidden;
		transition: all 0.6s ease;

		ul {
			list-style-type: none;

			li {
				cursor: pointer;
				margin-bottom: 3px;

				a {
					height: 45px;
					width: 100%;
					background-color: rgb(230, 230, 230);
					display: block;
					transition: all 0.6s ease;
					position: relative;

					h1 {
						display: inline-block;
						position: absolute;
						margin-top: 11px;
						font-family: "Roboto";
						font-weight: normal;
						margin-left: 10px;
						font-size: 18px;
						width: calc(100% - 45px);
						height: 45px;
						color: #1e1e1e;
					}
				}

				a:hover {
					background-color: #cacaca;
				}

				div {
					position: absolute;
					right: 0;
					width: 45px;
					height: 45px;
					display: inline-flex;
					flex-direction: column;
					justify-content: center;
					text-align: center;

					img {
						width: 40px;
						height: 40px;
						margin: 2.5px;
					}
				}
			}

			li:last-child {
				margin-bottom: 0;
			}
		}
	}

	.home-layout-usermenu-notificinternet {
		width: 65px;
	}

	.home-layout-usermenu-usermenu.visible {
		transform: translateY(0);
		visibility: inherit;
		box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 5px 0 rgba(0, 0, 0, 0.19);
		opacity: 1;
		cursor: pointer;
	}
}
@media all and (min-width: 800px) {
	.home-layout-usermenu {
		min-width: 200px !important;
		width: inherit !important;
		padding: 0 5px;

		.home-layout-usermenu-username {
			opacity: 1;
			visibility: inherit;
		}
	}
}
</style>
