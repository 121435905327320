<template>
	<div class="global-window" id="ResumoVenda">
		<div id="header">
			<h3>Resumo de Vendas</h3>
		</div>
		<div id="body">
			<h3>09/2020</h3>
			<div>
				<table>
					<tbody>
						<tr>
							<td>230192</td>
							<td>11/04/2019</td>
							<td>157,00</td>
						</tr>
					</tbody>
				</table>
				<h4>Total</h4>
			</div>
		</div>
	</div>
</template>

<script>
export default {}
</script>

<style lang="scss"></style>
