<template>
	<div id="vendaMercado">
		<div class="vendaMercado-element">
			<label>Previsão de Entrega:</label>
			<datepicker
				:value="dadosVenda.previsao"
				:language="ptBR"
				v-model="dadosVenda.previsao"
			></datepicker>
		</div>
		<div class="vendaMercado-element">
			<label>Ordem de compra</label>
			<input type="text" v-model="dadosVenda.ordemCompra" />
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex"
import Datepicker from "vuejs-datepicker"
import { ptBR } from "vuejs-datepicker/dist/locale"
export default {
	created() {},
	data() {
		return {
			ptBR: ptBR,
		}
	},

	computed: {
		...mapState(["dadosVenda"]),
	},
	components: {
		Datepicker,
	},
}
</script>

<style lang="scss">
#vendaMercado {
	width: 90%;
	padding: 15px;
	margin: 20px auto;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	.vendaMercado-element {
		margin: 0 20px;
		label {
			display: block;
		}
	}
}
</style>
