<template>
	<div class="global-window venda-sel">
		<div class="global-closebutton" @click="fechaJanela()">
			X
		</div>
		<FormaPagamento />
		<VendaMercado v-if="opt_mercado == 'true'" />
		<ResumoVenda />
	</div>
</template>

<script>
import FormaPagamento from "./FinVendaComponents/FormaPagamento"
import ResumoVenda from "./FinVendaComponents/ResumoVenda.vue"
import VendaMercado from "./FinVendaComponents/VendaMercado.vue"

export default {
	components: { FormaPagamento, ResumoVenda, VendaMercado },
	computed: {
		opt_mercado() {
			return process.env.VUE_APP_MOSTRA_OPT_MERCADO
		},
	},
	methods: {
		fechaJanela() {
			this.$emit("fechaJanela")
		},
	},
}
</script>

<style lang="scss">
.venda-sel {
	z-index: 2;
	margin: 0 auto;
	left: 0;
	right: 0;
	display: inline-block;
	width: 100% !important;
	height: 100% !important;
	overflow: auto;
}

.venda-sel-header {
	position: absolute;
	background-color: white;
	input {
		width: 90%;
		margin: 0 auto;
		display: block;
		font-size: 30px;
		font-family: "Lato";
		font-weight: lighter;
	}
}

.venda-sel-body {
	position: absolute;
	width: 100%;
	height: calc(100% - 85px);
	top: 85px;
	overflow: auto;

	.venda-sel-table {
		width: 100%;
		tr {
			cursor: pointer;
			&:hover {
				background-color: rgb(197, 197, 197);
			}
		}
	}
}
</style>
