<template>
	<div class="rota-cliente-lista">
		<div class="rota-cliente-lista-busca">
			<input type="text" v-model="buscaCliente" />
			<img src="@/assets/icones/lupa.png" />
		</div>
		<ul ref="scrollBar">
			<li
				v-for="(cliente, key) in Clientes"
				:key="key"
				@click="alteraCliente(cliente)"
				:class="[
					cliente.id == curCliente.clienteAtivo.id ? 'indexAtivo' : '',
					verificaVisita(cliente.logrota),
				]"
			>
				<p>Cod.:{{ cliente.codigo }}</p>
				<p class="nome">{{ cliente.razao }}</p>
				<p>
					<span>
						{{ cliente.razao1.length > 0 ? `(${cliente.razao1})` : "" }}
					</span>
				</p>
				<p>{{ cliente.bairro }}</p>
			</li>
		</ul>
	</div>
</template>

<script>
import { mapState } from "vuex"

export default {
	created() {
		this.Clientes = this.Cache.Clientes
		this.totalClientes = this.Cache.Clientes.length
	},

	mounted() {
		const { scrollBar } = this.$refs
		scrollBar.addEventListener("scroll", this.scrollChange)
		scrollBar.scrollTop = this.curCliente.scroll
	},

	data() {
		return {
			buscaCliente: "",
			Clientes: [],
			totalClientes: 0,
		}
	},
	computed: {
		...mapState(["Cache", "curCliente"]),
		clientesCache() {
			return this.Cache.Clientes
		},
	},
	methods: {
		alteraCliente(cliente) {
			this.$store.state.curCliente.clienteAtivo = JSON.parse(
				JSON.stringify(cliente)
			)
		},
		scrollChange() {
			const { scrollBar } = this.$refs
			this.$store.state.curCliente.scroll = scrollBar.scrollTop
		},
		verificaVisita(logRota) {
			if (logRota.length > 0) {
				if (logRota[0].obs == "VENDA EFETUADA") {
					return "vendido"
				} else {
					return "visitado"
				}
			} else {
				return ""
			}
		},
	},

	watch: {
		buscaCliente() {
			let filtro = (cliente) =>
				cliente.razao.indexOf(this.buscaCliente.toUpperCase()) != -1 ||
				String(cliente.codigo).startsWith(this.buscaCliente.toUpperCase())
			this.Clientes = this.Cache.Clientes.filter(filtro)
			this.alteraCliente(this.Clientes[0])
		},
		clientesCache() {
			this.Clientes = this.clientesCache
			if (this.totalClientes != this.Cache.Clientes.length) {
				this.$store.state.curCliente.scroll = 0
				this.totalClientes = this.Cache.Clientes.length
			}
		},
	},
}
</script>

<style lang="scss">
.vendido {
	background-color: #51da3f;
}
.visitado {
	background-color: rgb(236, 236, 100);
}
.rota-cliente-lista {
	height: 100% !important;
	border-right: 1px solid #ccc;
	width: calc(100% - 40px);
	.rota-cliente-lista-busca {
		height: 40px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		border-bottom: 1px solid #ccc;

		input[type="text"] {
			width: calc(100% - 40px);
			padding-left: 10px;
			height: 100%;
			font-size: 20px;
			font-weight: lighter;
			border: none;
			outline: none;
		}
		img {
			width: 40px;
			height: 40px;
			padding: 5px;
		}
	}

	ul {
		width: 100%;
		height: calc(100% - 40px);
		list-style-type: none;
		overflow-y: auto;

		li {
			width: 100%;
			min-height: 50px;
			padding: 5px;
			cursor: pointer;
			border-bottom: 2px solid rgb(146, 146, 146);
			transition: background-color 0.6s ease, color 0.6s ease;

			.nome {
				font-size: 15px;
				font-weight: lighter;
			}

			p {
				font-weight: bold;
				font-size: 13px;

				span {
					font-weight: lighter;
					font-size: 10px;
				}
			}

			&:first-child {
				margin-top: 0;
			}

			&:hover {
				background-color: #ccc;
			}
		}
	}

	.indexAtivo {
		background-color: rgb(70, 70, 70);
		color: white;

		&:hover {
			background-color: rgb(70, 70, 70);
		}
	}
}
</style>
