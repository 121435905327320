<template>
	<div class="home-layout-topbar">
		<h1 @click="showVersion()">{{ nomeEmpresa }}</h1>
		<div
			:class="['home-layout-topbar-menubutton', { rotate: mostraMenu }]"
			@click="alternaMenu()"
		>
			<img src="@/assets/icones/menu.png" />
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			nomeEmpresa: "",
		}
	},
	created() {
		this.nomeEmpresa = process.env.VUE_APP_NOME_EMPRESA
	},
	mounted() {
		window.onresize = () => {
			if (window.innerWidth > 800 && this.$store.state.mostraMenu == true) {
				this.$store.state.mostraMenu = false
			}
		}
	},
	computed: {
		mostraMenu() {
			return this.$store.state.mostraMenu
		},
	},
	methods: {
		alternaMenu() {
			this.$store.state.mostraMenu = !this.$store.state.mostraMenu
		},
		showVersion() {
			var horaAtual = new Date()

			this.$vex.dialog.alert({
				message: `Versão: ${
					process.env.PACKAGE_VERSION
				} | Hora Atual: ${this.$time(horaAtual).format("HH:mm")}`,
			})
		},
	},
}
</script>

<style lang="scss">
.home-layout-topbar {
	background-color: rgb(92, 92, 92);
	height: 45px;
	width: 100%;

	h1 {
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		height: 100%;
		font-size: 20px;
		max-width: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: lighter;
		color: white;
		overflow: hidden;
		word-break: break-all;
		text-align: center;
		cursor: pointer;
	}

	.home-layout-topbar-menubutton {
		width: 45px;
		height: 45px;
		background-color: #e9e9e9;
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		padding-left: 2.5px;
		cursor: pointer;
		img {
			width: 40px;
			height: 40px;
		}
		transition: all 0.6s ease;
		position: absolute;
		z-index: 99;
	}

	.home-layout-topbar-menubutton.rotate {
		transform: rotateZ(-90deg);
	}

	.botaoMenu:hover {
		background-color: #c4c4c4;
	}
}
@media all and (min-width: 800px) {
	.home-layout-topbar-menubutton {
		opacity: 0;
		visibility: hidden;
	}
}
</style>
