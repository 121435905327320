var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rota-cliente-mapa",style:({ width: `calc(100% - ${_vm.curCliente.mapsize}px)` })},[_c('GmapMap',{staticClass:"rota-cliente-mapa-gmap",attrs:{"center":{
			lat: parseFloat(_vm.curCliente.clienteAtivo.lat),
			lng: parseFloat(_vm.curCliente.clienteAtivo.lon),
		},"zoom":_vm.mapaZoom}},[_c('gmap-info-window',{attrs:{"position":{
				lat: parseFloat(_vm.curCliente.clienteAtivo.lat),
				lng: parseFloat(_vm.curCliente.clienteAtivo.lon),
			},"opened":_vm.mostraInfo},on:{"closeclick":function($event){_vm.mostraInfo = false}}},[_c('h1',[_vm._v(_vm._s(_vm.curCliente.clienteAtivo.razao))]),(_vm.curCliente.clienteAtivo.razao1.length > 0)?_c('h3',[_vm._v(" ("+_vm._s(_vm.curCliente.clienteAtivo.razao1)+") ")]):_vm._e(),_c('p',[_vm._v(" "+_vm._s(_vm.curCliente.clienteAtivo.ender)+" "+_vm._s(_vm.curCliente.clienteAtivo.compl)+" - "+_vm._s(_vm.curCliente.clienteAtivo.cidade)+" "+_vm._s(_vm.curCliente.clienteAtivo.bairro)+" "+_vm._s(_vm.curCliente.clienteAtivo.uf)+" ")])]),_vm._l((_vm.Cache.Clientes),function(cliente,key){return _c('GmapMarker',{key:key,attrs:{"position":{
				lat: parseFloat(cliente.lat),
				lng: parseFloat(cliente.lon),
			},"clickable":true,"draggable":false},on:{"click":function($event){return _vm.alteraCliente(key)}}})})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }