<template>
	<div class="rota-cliente-menu">
		<button class="rota-cliente-btncadastro" @click="abreForm(true)">
			+
		</button>
		<div class="rota-cliente-btncliente">
			<button @click="abreVenda()">
				<img src="@/assets/icones/sell.svg" alt="" />
			</button>
			<button @click="abreMap()">
				<img src="@/assets/icones/map.svg" alt="" />
			</button>
			<button @click="marcaVisita()">
				<img src="@/assets/icones/visit.svg" alt="" />
			</button>
			<button @click="abreForm(false)">
				<img src="@/assets/icones/info.svg" alt="" />
			</button>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex"
export default {
	computed: {
		...mapState(["dadosVenda", "Cache", "curCliente"]),
		produtosCliente() {
			return this.Cache.ProdutosCliente.filter((prod) => {
				return prod.id_cliente == this.$store.state.curCliente.clienteAtivo.id
			})
		},
	},

	methods: {
		abreForm(param) {
			this.$emit("abreForm", param)
		},

		abreMap() {
			let { lat, lon } = this.curCliente.clienteAtivo

			window.open(
				`https://www.google.com/maps/search/?api=1&query=${lat},${lon}`,
				"_blank"
			)
		},

		detalhaCliente() {
			this.detalha = this.clienteAtivo
			this.mostraForm = true
		},

		marcaVisita() {
			let $this = this
			this.$vex.dialog.open({
				message: `Deseja marcar ${this.curCliente.clienteAtivo.razao} como visitado?`,
				input: [
					'<input name="obs" type="text" placeholder="Observação" required />',
				].join(""),
				callback: function(data) {
					if (data) {
						let user = JSON.parse(localStorage.user)
						let objVisita = {
							id_rota: $this.Cache.Rota.id,
							id_cliente: $this.curCliente.clienteAtivo.id,
							id_usuario: user.id,
							visitedAt: Date.now(),
							obs: data.obs,
						}
						$this.$api.post("/logrota", objVisita).then(() => {
							$this.$reloadCache("RotaUsuario")
						})
					}
				},
			})
		},

		verificaProdutosCliente() {
			let retorno = false

			if (this.dadosVenda.produtospedido.length > 0) {
				if (this.produtosCliente.length > 0) {
					this.$vex.dialog.confirm({
						message:
							"Cliente com lista de produtos diferenciada, o carrinho não pode ser reaproveitado, e será apagado.",
						callback: (carrinho) => {
							if (carrinho) {
								this.dadosVenda.produtospedido = []
								retorno = true
							}
						},
					})
				}
			} else {
				retorno = true
			}

			return retorno
		},

		defineDadosVenda() {
			this.$set(this.dadosVenda, "cliente", this.curCliente.clienteAtivo)

			if (this.curCliente.clienteAtivo.id_nivpre != null) {
				this.$set(
					this.dadosVenda,
					"id_nivpre",
					this.curCliente.clienteAtivo.id_nivpre
				)
			}
			if (this.curCliente.clienteAtivo.id_condpgto != null) {
				this.$set(
					this.dadosVenda,
					"id_condpgto",
					this.curCliente.clienteAtivo.id_condpgto
				)
			}

			this.$set(this.dadosVenda, "id_cliente", this.curCliente.clienteAtivo.id)
			this.$set(this.dadosVenda, "tVenda", "V")
			this.$router.push("/venda")
		},

		abreVenda() {
			if (
				this.curCliente.clienteAtivo.obs != "" &&
				this.curCliente.clienteAtivo.obs != null
			) {
				this.$vex.dialog.confirm({
					message: "Observações do Cliente:",
					input: this.curCliente.clienteAtivo.obs,
					callback: (response) => {
						if (response) {
							if (this.dadosVenda.produtospedido.length > 0) {
								if (this.produtosCliente.length > 0) {
									this.$vex.dialog.confirm({
										message:
											"Cliente com lista de produtos diferenciada, o carrinho não pode ser reaproveitado, e será apagado.",
										callback: (carrinho) => {
											if (carrinho) {
												this.dadosVenda.produtospedido = []
												this.defineDadosVenda()
											}
										},
									})
								}
							} else {
								this.defineDadosVenda()
							}
						}
					},
				})
			} else {
				if (this.dadosVenda.produtospedido.length > 0) {
					if (this.produtosCliente.length > 0) {
						this.$vex.dialog.confirm({
							message:
								"Cliente com lista de produtos diferenciada, o carrinho não pode ser reaproveitado, e será apagado.",
							callback: (carrinho) => {
								if (carrinho) {
									this.dadosVenda.produtospedido = []
									this.defineDadosVenda()
								}
							},
						})
					}
				} else {
					this.defineDadosVenda()
				}
			}
		},
	},
}
</script>

<style lang="scss">
.rota-cliente-menu {
	width: 40px;
	height: 100%;

	.rota-cliente-btncadastro {
		background-color: inherit;
		border: none;
		width: 100%;
		height: 40px;
		font-size: 30px;
		font-weight: lighter;
		transition: background-color 0.6s ease;
		border-bottom: 1px solid #ccc;
		cursor: pointer;

		&:hover {
			background-color: #ccc;
		}
	}

	.rota-cliente-btncliente {
		button {
			width: 40px;
			height: 40px;
			padding: 5px;
			border: none;
			background-color: inherit;
			cursor: pointer;
			transition: background-color 0.6s ease;
			margin-bottom: 10px;

			&:hover {
				background-color: #ccc;
			}
			img {
				width: 100%;
			}
		}
	}
}
</style>
