<template>
	<div>
		<div class="venda-carrinho-header">
			<div @click="trocaCliente()">
				<label>Cliente:</label>
				<h1
					v-if="dadosVenda.cliente != undefined"
					class="venda-carrinho-header-nome"
				>
					{{ dadosVenda.cliente.codigo }} -
					{{ dadosVenda.cliente.razao }}
					{{
						dadosVenda.cliente.razao1.length > 0
							? `(${dadosVenda.cliente.razao1})`
							: ""
					}}
				</h1>
			</div>
		</div>
		<div class="venda-carrinho-selection">
			<div v-if="!nivPreDefinido">
				<h1>Nível de Preço:</h1>
				<select v-model="dadosVenda.id_nivpre" :disabled="nivPreDefinido">
					<option
						v-for="nivel in Cache.NivPre"
						:key="nivel.id"
						:value="nivel.id"
					>
						{{ nivel.descricao }}
					</option>
				</select>
			</div>
			<div v-if="opt_mercado == 'true'">
				<h1>Tipo de Venda:</h1>
				<select v-model="dadosVenda.tVenda">
					<option value="V">Venda</option>
					<option value="T">Troca</option>
					<option value="B">Bonificação</option>
				</select>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex"
export default {
	computed: {
		...mapState(["dadosVenda", "Cache"]),
		nivPreDefinido() {
			return this.dadosVenda.cliente.id_nivpre != null ? true : false
		},
		nivpre() {
			return this.dadosVenda.id_nivpre
		},
		tVenda() {
			return this.dadosVenda.tVenda
		},
		produtosCliente() {
			return this.Cache.ProdutosCliente.filter((prod) => {
				return prod.id_cliente == this.dadosVenda.cliente.id
			})
		},
		opt_mercado() {
			return process.env.VUE_APP_MOSTRA_OPT_MERCADO
		},
	},

	created() {
		this.aplicaNivelPreco()
		this.corrigeQuant()
	},

	methods: {
		aplicaNivelPreco() {
			this.$set(
				this.dadosVenda,
				"nivpre",
				this.Cache.NivPre[this.dadosVenda.id_nivpre - 1]
			)

			if (this.produtosCliente.length == 0) {
				if (this.Cache.NivPre[this.dadosVenda.id_nivpre - 1].acrescimo != 0) {
					this.dadosVenda.produtospedido.forEach((produto) => {
						produto.valor_vend =
							produto.valor_orig +
							produto.valor_vend *
								(this.Cache.NivPre[this.dadosVenda.id_nivpre - 1].acrescimo /
									100)
					})
				} else {
					this.dadosVenda.produtospedido.forEach((produto) => {
						produto.valor_vend =
							produto.valor_orig -
							produto.valor_vend *
								(this.Cache.NivPre[this.dadosVenda.id_nivpre - 1].desconto /
									100)
					})
				}
			}
		},
		trocaCliente() {
			if (this.dadosVenda.produtospedido.length > 0) {
				if (this.produtosCliente.length > 0) {
					this.$vex.dialog.confirm({
						message:
							"O cliente atual possui uma lista de produtos diferenciada, ao troca-lo, o carrinho será apagado.",
						callback: (response) => {
							if (response) {
								this.dadosVenda.produtospedido = []
								this.$router.push("/clientes")
							}
						},
					})
				} else {
					this.$router.push("/clientes")
				}
			} else {
				this.$router.push("/clientes")
			}
		},
		multiploProximo(fator, valor, maior = true) {
			let valAtual = valor
			while (valAtual % fator != 0) {
				if (maior) {
					valAtual++
				} else {
					valAtual--
				}
			}
			if (valAtual == 0) {
				return this.multiploProximo(fator, valor)
			}
			return valAtual
		},

		corrigeQuant() {
			if (this.tVenda == "V") {
				for (const produto of this.dadosVenda.produtospedido) {
					if (produto.quant % produto.fator != 0) {
						this.$vex.dialog.alert({
							message: `Quantidade ${produto.quant} para o produto ${produto.descricao} é inválida (Não é múltiplo de ${produto.fator}), a quantidade será corrigida para o valor correto mais próximo.`,
						})

						produto.quant = this.multiploProximo(
							produto.fator,
							produto.quant,
							false
						)
					}
				}
			}
		},
	},
	watch: {
		nivpre() {
			this.aplicaNivelPreco()
		},
		tVenda() {
			this.corrigeQuant()
		},
	},
}
</script>

<style lang="scss">
.venda-carrinho-header {
	width: 100%;
	height: 50px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.5);
	div {
		cursor: pointer;
		text-align: center;

		label {
			display: block;
			font-family: "Roboto";
			font-size: 15px;
		}

		h1 {
			font-family: "Lato";
			font-size: 1.5em;
			font-weight: lighter;
			white-space: nowrap;
			width: 90%;
			margin: 0 auto;
			overflow: hidden;
		}
	}
}
.venda-carrinho-selection {
	width: 100%;
	height: 30px;
	display: flex;
	flex-wrap: wrap;
	overflow: auto;
	justify-content: center;

	h1 {
		display: inline-block;
		font-size: 1em;
		font-family: "Lato";
		font-weight: 300;
	}

	select {
		height: 22px;
		width: 150px;
		margin: 4px;

		text-align: center;
		font-family: "Lato";
	}
}
</style>
