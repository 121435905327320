<template>
	<div class="form-div-field">
		<div class="form-field form-field-cep">
			<label>CEP *</label>
			<div>
				<input
					type="number"
					maxlength="8"
					v-model="Cliente.cep"
					name="cep"
					placeholder="Ex: 25720120"
					:disabled="Cliente.id == undefined ? false : true"
					required
				/>
				<button
					@click.prevent="consultaCep()"
					:disabled="Cliente.id == undefined ? false : true"
				>
					<img src="@/assets/icones/lupa.png" />
				</button>
			</div>
		</div>
		<div class="form-field">
			<label>Rua *</label>
			<input
				type="text"
				v-model="Cliente.ender"
				name="ender"
				placeholder="Rua"
				:disabled="Cliente.id == undefined ? false : true"
				required
			/>
		</div>
		<div class="form-field">
			<label>Complemento *</label>
			<input
				type="text"
				v-model="Cliente.compl"
				name="compl"
				placeholder="Nro, lote, apto..."
				:disabled="Cliente.id == undefined ? false : true"
				required
			/>
		</div>
		<div class="form-field">
			<label>Bairro *</label>
			<input
				type="text"
				v-model="Cliente.bairro"
				name="bairro"
				placeholder="Bairro"
				:disabled="Cliente.id == undefined ? false : true"
				required
			/>
		</div>
		<div class="form-field">
			<label>Cidade *</label>
			<input
				type="text"
				v-model="Cliente.cidade"
				name="cidade"
				placeholder="Cidade"
				:disabled="Cliente.id == undefined ? false : true"
				required
			/>
		</div>
		<div class="form-field">
			<label>Estado *</label>
			<select
				v-model="Cliente.uf"
				name="uf"
				:disabled="Cliente.id == undefined ? false : true"
				required
			>
				<option value="AC">Acre</option>
				<option value="AL">Alagoas</option>
				<option value="AP">Amapá</option>
				<option value="AM">Amazonas</option>
				<option value="BA">Bahia</option>
				<option value="CE">Ceará</option>
				<option value="DF">Distrito Federal</option>
				<option value="ES">Espírito Santo</option>
				<option value="GO">Goiás</option>
				<option value="MA">Maranhão</option>
				<option value="MT">Mato Grosso</option>
				<option value="MS">Mato Grosso do Sul</option>
				<option value="MG">Minas Gerais</option>
				<option value="PA">Pará</option>
				<option value="PB">Paraíba</option>
				<option value="PR">Paraná</option>
				<option value="PE">Pernambuco</option>
				<option value="PI">Piauí</option>
				<option value="RJ">Rio de Janeiro</option>
				<option value="RN">Rio Grande do Norte</option>
				<option value="RS">Rio Grande do Sul</option>
				<option value="RO">Rondônia</option>
				<option value="RR">Roraima</option>
				<option value="SC">Santa Catarina</option>
				<option value="SP">São Paulo</option>
				<option value="SE">Sergipe</option>
				<option value="TO">Tocantins</option>
				<option value="EX">Estrangeiro</option>
			</select>
		</div>
	</div>
</template>

<script>
import Axios from "axios"
var cep = Axios.create({
	headers: {
		"Content-Type": "application/json",
	},
	baseURL: "https://viacep.com.br/ws/",
})

export default {
	props: ["Cliente"],
	methods: {
		consultaCep() {
			if (
				this.Cliente.cep != undefined &&
				this.Cliente.cep != "" &&
				this.Cliente.cep.length == 8
			) {
				cep.get(`${this.Cliente.cep}/json/unicode`).then((res) => {
					if (!res.data.erro) {
						this.Cliente.ender = res.data.logradouro
						this.Cliente.bairro = res.data.bairro
						this.Cliente.uf = res.data.uf
						this.Cliente.cidade = res.data.localidade
						this.Cliente.cod_ibge = res.data.ibge
					} else {
						this.$vex.dialog.alert("Cep não encontrado!")
					}
				})
			} else {
				this.$vex.dialog.alert("Cep inválido")
			}
		},
	},
}
</script>

<style lang="scss">
.form-field-cep {
	div {
		display: flex;
		width: 100% !important;
		padding: 0;
		input[type="number"] {
			width: calc(100% - 40px) !important;
			padding: 5px;
			border-right: none !important;
		}
		button {
			width: 30px;
			border: 1px solid rgb(146, 146, 146);
			padding: 2px;
			background-color: inherit;
			cursor: pointer;
			img {
				width: 100%;
			}
		}
	}
}
</style>
