<template>
	<div class="global-window">
		<div class="produtos">
			<div class="global-window-header produtos-header">
				<h1>Selecione o Produto</h1>
				<div class="produtos-subheader">
					<div class="produtos-selectGrupo">
						<select v-model="curGrupo">
							<option
								:value="grupo.id"
								v-for="(grupo, key) in Cache.Produtos"
								:key="key"
							>
								{{ grupo.nome }}
							</option>
							<option value="0">TODOS</option>
						</select>
					</div>
					<div class="produtos-busca">
						<input
							type="text"
							v-model="Busca"
							class="input-produto"
							placeholder="Busca do Produto"
							@input="isTyping = true"
						/>
					</div>
				</div>
			</div>
			<div class="produtos-body">
				<table class="global-table produtos-table">
					<thead>
						<th>Descrição</th>
						<th>Vl. Venda</th>
						<th>Código</th>
					</thead>
					<tbody>
						<template v-if="listaAtual.length > 0">
							<tr v-for="(produto, key) in listaAtual" :key="key">
								<td>
									<p>
										{{ produto.descricao }}
									</p>
									<img
										class="produtos-promoicon"
										v-if="produto.vl_promo > 0"
										src="@/assets/icones/promo.svg"
									/>
								</td>
								<td align="right">
									<p>
										{{ vlProduto(produto, Produtos[produto.key].quant) }}
									</p>
								</td>
								<td align="center">
									<p>
										{{ produto.cod_est }}
									</p>
								</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex"
import _ from "lodash"

export default {
	data() {
		return {
			Busca: "",
			Produtos: [],
			listaAtual: [],
			tipoBusca: 0,
			curGrupo: 1,
			isTyping: false,
		}
	},

	computed: {
		...mapState(["mostraSelProduto", "Cache"]),
	},

	created() {
		this.defineProdutos()
		this.filtraProdutosGrupo()
	},

	methods: {
		defineProdutos() {
			this.Cache.Produtos.forEach((grupo) => {
				this.Produtos = this.Produtos.concat(grupo.produtos)
			})

			this.Produtos.forEach((produto, key) => {
				this.$set(produto, "key", key)
				this.$set(produto, "quant", "")
			})
		},

		filtraProdutosGrupo() {
			if (this.curGrupo == 0) {
				this.listaAtual = this.Produtos
			} else {
				this.listaAtual = this.Produtos.filter(
					(produto) => produto.id_grupo == this.curGrupo
				)
			}
			this.Busca = ""
		},

		buscaProduto() {
			if (this.Busca != "") {
				let filtros = [
					(produto) =>
						produto.descricao.indexOf(this.Busca.toUpperCase()) != -1 ||
						produto.cod_est.startsWith(this.Busca.toUpperCase()),
				]
				this.listaAtual = this.Produtos.filter(filtros[this.tipoBusca])
			} else {
				this.filtraProdutosGrupo()
			}
		},

		vlProduto(produto, quant) {
			let valor = produto.valor_vend

			if (produto.vl_promo > 0) {
				valor = produto.vl_promo
			}

			if (quant > 0) {
				valor = valor * quant
			}
			return this.$moeda(valor)
		},
	},

	watch: {
		Busca: _.debounce(function() {
			this.isTyping = false
		}, 1000),
		isTyping: function(value) {
			if (!value) {
				this.buscaProduto()
			}
		},
		curGrupo() {
			this.filtraProdutosGrupo()
		},
	},
}
</script>

<style lang="scss">
.produtos {
	position: absolute !important;
	z-index: 2;
	margin: 0 auto;
	left: 0;
	right: 0;
	display: inline-block;
	width: 100% !important;
	height: 100% !important;
	.produtos-header {
		position: absolute;
		background-color: white;

		.produtos-subheader {
			width: 90%;
			margin: 0 auto;
			.produtos-selectGrupo {
				height: 30px;
				margin-bottom: 10px;
				select {
					width: 100%;
					height: 100%;
				}
			}
			.produtos-busca {
				display: flex;
				.input-produto {
					width: 100%;
					display: inline-block;
					font-family: "Lato";
					font-weight: lighter;
					height: 40px;
					padding: 5px;
					font-size: 20px;
				}
			}
		}
	}

	.produtos-body {
		position: absolute;
		width: 100%;
		overflow: auto;
		top: 130px !important;
		height: calc(100% - 130px) !important;

		.produtos-table {
			width: 100%;
			tr {
				cursor: pointer;
				&:hover {
					background-color: rgb(197, 197, 197);
				}

				td {
					height: 25px;
					p {
						height: 100%;
						display: inline-block;
						font-size: 18px;
					}
					.produtos-promoicon {
						height: 100%;
						margin-left: 5px;
						display: inline-flex;
						justify-content: center;
						align-items: center;
					}
					input {
						margin: 0;
						width: 100%;
						text-align: center;
					}
				}
			}
		}
	}
}
</style>
