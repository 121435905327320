<template>
	<div class="form-div-tel">
		<table>
			<thead>
				<tr>
					<th>Nome</th>
					<th>Telefone</th>
					<th width="40px"></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="tel in telFax" :key="tel.id">
					<td>{{ tel.nome }}</td>
					<td>{{ tel.tel }}</td>
					<td>
						<button @click.prevent="removeTel(tel.id)">
							<img src="@/assets/icones/lixo.svg" />
						</button>
					</td>
				</tr>
				<tr v-for="(tel, key) in tempTel" :key="key">
					<td>{{ tel.nome }}</td>
					<td>{{ tel.tel }}</td>
					<td>
						<button @click.prevent="removeTempTel(key)">
							<img src="@/assets/icones/lixo.svg" />
						</button>
					</td>
				</tr>
				<tr>
					<td><input type="text" v-model="nNome" /></td>
					<td><input type="text" v-model="nTel" /></td>
					<td><button @click.prevent="addTel()">+</button></td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
export default {
	props: ["Cliente", "tempTel"],

	data() {
		return {
			telFax: [],
			nNome: "",
			nTel: "",
		}
	},

	created() {
		this.telFax = this.Cliente.telfax
	},

	methods: {
		addTel() {
			let tel = {
				nome: this.nNome,
				tel: this.nTel,
				tipo: 1,
			}
			if (this.Cliente.id != undefined) {
				tel.id_cliente = this.Cliente.id

				this.$api.post("/telfax", tel).then(() => {
					this.$api.get(`/clientes/id/${this.Cliente.id}`).then((response) => {
						this.telFax = response.data[0].telfax
					})
				})
			} else {
				this.tempTel.push(tel)
			}
			this.nNome = ""
			this.nTel = ""
		},

		removeTel(key) {
			this.$vex.dialog.confirm({
				message: "Apagar telefone?",
				callback: (response) => {
					if (response) {
						this.$api.delete(`/telfax/${key}`).then(() => {
							this.$api
								.get(`/clientes/id/${this.Cliente.id}`)
								.then((response) => {
									this.telFax = response.data[0].telfax
								})
						})
					}
				},
			})
		},

		removeTempTel(key) {
			this.$vex.dialog.confirm({
				message: "Apagar telefone?",
				callback: (response) => {
					if (response) {
						this.tempTel.splice(key, 1)
					}
				},
			})
		},
	},
}
</script>

<style lang="scss">
.form-div-tel {
	width: 100%;
	padding: 10px;
	table {
		margin: 0 auto;
		border-collapse: collapse;
		width: 90%;
		border: 1px solid #ccc;
		thead {
			background-color: #ccc;
			tr {
				th {
					font-weight: lighter;
					font-size: 25px;
				}
			}
		}
		tbody {
			tr {
				border-bottom: 1px solid #ccc;
				td {
					border-right: 1px solid #ccc;
					padding: 10px;
					input[type="text"] {
						width: 100%;
						height: 100%;
						font-size: 15px;
						border: none;
						outline: none;
					}
					button {
						height: 40px;
						width: 40px;
						border: none;
						background-color: none;
						cursor: pointer;
						font-size: 30px;
						font-weight: lighter;

						img {
							height: 30px;
						}
					}

					&:last-child() {
						border-right: none;
					}
				}
			}
		}
	}
}
</style>
