import api from "../services/api"
import Store from "../store"
import Vue from "vue"
import "../plugins/online"
let $this = new Vue()

let reloadCache = (param = "all") => {
	return new Promise(async (finish) => {
		let cacheFunc = {
			Usuario() {
				return new Promise((resolve) => {
					Store.state.loadMessage = "Usuario"

					api
						.get("/usuarios/update")
						.then((res) => {
							localStorage.user = JSON.stringify(res.data)
							Store.state.usuario = res.data
							resolve()
						})
						.catch(() => {
							resolve()
						})
				})
			},
			Menus() {
				return new Promise((resolve) => {
					Store.state.loadMessage = "Menus"
					api
						.get("/menus")
						.then((res) => {
							$this.$set(Store.state.Cache, "Menus", res.data)
							resolve()
						})
						.catch(() => {
							resolve()
						})
				})
			},
			Produtos() {
				return new Promise((resolve) => {
					Store.state.loadMessage = "Produtos"
					api
						.get("/ProdutosGrupo")
						.then((res) => {
							$this.$set(Store.state.Cache, "Produtos", res.data)
							resolve()
						})
						.catch(() => {
							resolve()
						})
				})
			},
			CondPgto() {
				return new Promise((resolve) => {
					Store.state.loadMessage = "CondPgto"
					api
						.get("/condpgto")
						.then((res) => {
							$this.$set(Store.state.Cache, "CondPgto", res.data)
							resolve()
						})
						.catch(() => {
							resolve()
						})
				})
			},
			NivPre() {
				return new Promise((resolve) => {
					Store.state.loadMessage = "NivPre"
					api
						.get("/nivpre")
						.then((res) => {
							$this.$set(Store.state.Cache, "NivPre", res.data)
							resolve()
						})
						.catch(() => {
							resolve()
						})
				})
			},
			PedidosPendentes() {
				return new Promise(async (resolve) => {
					Store.state.loadMessage = "Pedidos Pendentes"

					while (Store.state.filaPedidos.length > 0) {
						await api.post("/vendas", Store.state.filaPedidos[0]).catch(() => {
							resolve()
						})

						let user = JSON.parse(localStorage.user)

						let objVisita = {
							id_rota: Store.state.Cache.Rota.id,
							id_cliente: Store.state.filaPedidos[0].cliente.id,
							id_usuario: user.id,
							visitedAt: Date.now(),
							obs: "VENDA EFETUADA",
						}

						await api.post("/logrota", objVisita).catch(() => {
							resolve()
						})

						Store.state.filaPedidos.splice(0, 1)
					}
					resolve()
				})
			},
			Pedidos() {
				return new Promise((resolve) => {
					Store.state.loadMessage = "Lista de Pedidos"
					api
						.get("/pedidos/vendedor")
						.then((res) => {
							$this.$set(Store.state.Cache, "Pedidos", res.data)
							resolve()
						})
						.catch(() => {
							resolve()
						})
				})
			},
			StatusPedido() {
				return new Promise((resolve) => {
					Store.state.loadMessage = "StatusPedido"
					api
						.get("/statuspedido")
						.then((res) => {
							$this.$set(Store.state.Cache, "StatusPedido", res.data)
							resolve()
						})
						.catch(() => {
							resolve()
						})
				})
			},
			RotaUsuario() {
				return new Promise((resolve) => {
					Store.state.loadMessage = "RotaUsuario"
					api
						.get("/rotausuario")
						.then((res) => {
							Store.state.Cache.Clientes = res.data.Clientes
							Store.state.Cache.Rota = res.data
							resolve()
						})
						.catch(() => {
							resolve()
						})
				})
			},
			Notificacoes() {
				return new Promise((resolve) => {
					Store.state.loadMessage = "Notificações"
					api
						.get("/usuariosnotificacao")
						.then((res) => {
							$this.$set(Store.state.Cache, "Notificacoes", res.data)
							resolve()
						})
						.catch(() => {
							resolve()
						})
				})
			},
			ProdutosCliente() {
				return new Promise((resolve) => {
					Store.state.loadMessage = "ProdutosCliente"
					api
						.get("/produtoscliente")
						.then((res) => {
							$this.$set(Store.state.Cache, "ProdutosCliente", res.data)
							resolve()
						})
						.catch(() => {
							resolve()
						})
				})
			},
			GrupoClientes() {
				return new Promise((resolve) => {
					Store.state.loadMessage = "GrupoClientes"
					api
						.get("/grupoclientes")
						.then((res) => {
							$this.$set(Store.state.Cache, "GrupoClientes", res.data)
							resolve()
						})
						.catch(() => {
							resolve()
						})
				})
			},
		}

		let attrs = Object.keys(cacheFunc)
		if ($this.isOnline) {
			if (localStorage.user) {
				if (param == "all") {
					for (let i = 0; i < attrs.length; ) {
						await cacheFunc[attrs[i]]().then(() => {
							Store.state.loadMessage = ""
							i++
						})
					}
				} else {
					await cacheFunc[param]()
				}
				Store.state.loading = false
			}
			finish()
		}
	})
}

export default reloadCache

Vue.use({
	install(Vue) {
		Vue.prototype.$reloadCache = reloadCache
	},
})
