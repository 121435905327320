<template>
	<div class="global-window notificacoes">
		<h1 class="notificacoes-header">Notificações</h1>
		<table class="global-table notificacoes-table">
			<thead>
				<th>Texto</th>
				<th>Enviada em:</th>
				<th>Lida em:</th>
				<th>Lida</th>
			</thead>
			<tbody>
				<tr v-for="(notificacao, key) in Cache.Notificacoes" :key="key">
					<td>{{ notificacao.notificacao.texto }}</td>
					<td>{{ converteData(notificacao.createdAt) }}</td>
					<td>
						{{
							notificacao.status != 0 ? converteData(notificacao.updatedAt) : ""
						}}
					</td>
					<td>
						<button @click="marcaLida(key)">
							<img
								:src="
									notificacao.status == 0
										? require('@/assets/icones/not-viewed.svg')
										: require('@/assets/icones/viewed.svg')
								"
							/>
						</button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import { mapState } from "vuex"

export default {
	created() {
		this.$socket.io.on("updatedUsuariosNotificacao", () => {
			this.updateCache()
		})
	},

	computed: {
		...mapState(["Cache"]),
	},

	methods: {
		converteData(data) {
			return this.$time(data).format("DD/MM/YYYY")
		},

		marcaLida(key) {
			this.$api.put(`/usuariosnotificacao/${this.Cache.Notificacoes[key].id}`, {
				status: 1,
			})
		},

		async updateCache() {
			let Notificacoes = await this.$api.get("/usuariosnotificacao")
			this.Cache.Notificacoes = Notificacoes.data
		},
	},
}
</script>

<style lang="scss">
.notificacoes {
	overflow: auto;
	.notificacoes-header {
		width: 100%;
		text-align: center;
		margin: 15px 0;
	}
	.notificacoes-table {
		width: 90%;
		margin: 0 auto;
		tbody {
			tr {
				td {
					padding: 5px;
					text-align: center;
					button {
						border: none;
						background-color: inherit;
						transition: background-color 0.6s ease;
						img {
							width: 35px;
							height: 35px;
						}
						&:hover {
							background-color: #ccc;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
}
</style>
