<template>
	<div
		class="home-layout-leftmenu"
		:class="{ 'home-layout-leftmenu-showmenu': mostraMenu }"
	>
		<ul>
			<template v-for="(menu, key) in Menu">
				<li
					:key="key"
					v-if="menu.nav == 2"
					@click="$store.state.mostraMenu = false"
				>
					<router-link :to="menu.funcao">
						<h1>{{ menu.nome }}</h1>
						<div>
							<img :src="menu.urlIcone" class="icone" />
						</div>
					</router-link>
				</li>
			</template>
		</ul>
	</div>
</template>

<script>
export default {
	computed: {
		mostraMenu() {
			return this.$store.state.mostraMenu
		},
		Menu() {
			return this.$store.state.Cache.Menus
		}
	}
}
</script>

<style lang="scss">
.home-layout-leftmenu {
	height: calc(100vh - 40px);
	width: 200px;
	background-color: rgb(126, 126, 126);
	transition: transform 0.6s ease;
	z-index: 4;
	ul {
		list-style-type: none;
		li {
			cursor: pointer;
			margin-bottom: 3px;

			a {
				position: relative;
				background-color: #a5a5a5;
				transition: background-color 0.6s ease;
				width: 100%;
				height: 45px;
				display: block;

				h1 {
					width: calc(100% - 45px);
					height: 100%;
					padding-left: 10px;
					display: inline-flex;
					flex-direction: column;
					justify-content: center;
					position: absolute;
					color: white;
					font-size: 18px;
					font-weight: normal;
					font-family: "Roboto";
					text-shadow: 1px 1px #2c2c2c;
				}
				div {
					height: 45px;
					width: 45px;
					background-color: white;
					position: absolute;
					right: 0;
					display: inline-flex;
					flex-direction: column;
					justify-content: center;
					padding-left: 2px;
					transition: background-color 0.6s ease;
					.icone {
						width: 40px;
						height: 40px;
					}
				}
				div:hover {
					background-color: #ccc;
				}
			}
			a:hover {
				background-color: #ccc;
			}
		}
	}
}

.home-layout-leftmenu-showmenu {
	transform: translateX(0) !important;
}

@media all and (max-width: 800px) {
	.home-layout-leftmenu {
		transform: translateX(-155px);
	}
}

@media all and (max-width: 500px) {
	.home-layout-leftmenu {
		transform: translateX(-200px);
	}
}
</style>
