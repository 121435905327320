<template>
	<div class="app">
		<Loading />
		<template v-if="Rota == '/login'">
			<router-view />
		</template>
		<template v-else>
			<TopBar />
			<UserMenu />
			<template v-if="expediente">
				<LeftMenu />
				<div
					:class="['global-overlay', { 'home-layout-showmenu': mostraMenu }]"
					@click="alternaMenu()"
				></div>
				<div class="home-layout-pagebody"><router-view /></div>
			</template>
			<template v-else>
				<h1 class="expediente">
					Fora do horário de Expediente
					<br />
					<br />
					Hora Atual: {{ $time(new Date()).format("HH:mm:ss") }}
					<br />
					Horário Permitido: De {{ expedienteUsuario.horaIni }} às
					{{ expedienteUsuario.horaFim }}
				</h1>
			</template>
		</template>
	</div>
</template>

<script>
import { mapState } from "vuex"
import { db } from "./db/db"

export default {
	data() {
		return {
			componenteParametro: this.$route.params.componente,
			hrAtual: new Date(),
		}
	},

	computed: {
		...mapState([
			"dadosVenda",
			"Cache",
			"filaPedidos",
			"rascunhoPedidos",
			"curCliente",
			"lastCheck",
		]),

		mostraMenu() {
			return this.$store.state.mostraMenu
		},

		Rota() {
			return this.$route.path
		},

		User() {
			return localStorage.user
		},

		Usuario() {
			return this.$store.state.usuario
		},

		Online() {
			return this.isOnline
		},

		envia_pedido_auto() {
			return process.env.VUE_APP_ENVIA_PEDIDO_AUTO
		},

		expedienteUsuario() {
			let retorno = {}
			if (this.Usuario.expediente != null && this.Usuario.expediente != "") {
				retorno = JSON.parse(this.Usuario.expediente)
			}
			return retorno
		},

		expediente() {
			let ret = false

			if (this.Usuario.expediente != null && this.Usuario.expediente != "") {
				let expediente = JSON.parse(this.Usuario.expediente)

				const hrIni = new Date()
				const hrFim = new Date()

				let diaValido = expediente.dias.indexOf(this.hrAtual.getDay())

				hrIni.setHours(
					expediente.horaIni.substring(0, 2),
					expediente.horaIni.substring(3, 5),
					0
				)
				hrFim.setHours(
					expediente.horaFim.substring(0, 2),
					expediente.horaFim.substring(3, 5),
					0
				)

				if (this.hrAtual >= hrIni && this.hrAtual <= hrFim && diaValido >= 0) {
					ret = true
				}
			} else {
				ret = true
			}

			return ret
		},
	},

	async created() {


		if (localStorage.user != undefined) {
			this.$socket.openConnection()
		}

		if (this.Rota != "/login" && localStorage.user != undefined) {
      const data = await db.Cache.toArray()      
			await this.$set(this.$store.state, "Cache", JSON.parse(data[0].json))
			if (localStorage.user != undefined) {
				this.$store.state.usuario = JSON.parse(localStorage.user)
			}
			if (localStorage.filaPedidos != undefined) {
				this.$set(
					this.$store.state,
					"filaPedidos",
					JSON.parse(localStorage.filaPedidos)
				)
			}
			if (localStorage.dadosVenda != undefined) {
				this.$set(
					this.$store.state,
					"dadosVenda",
					JSON.parse(localStorage.dadosVenda)
				)
			}
			if (localStorage.rascunhoPedidos != undefined) {
				this.$set(
					this.$store.state,
					"rascunhoPedidos",
					JSON.parse(localStorage.rascunhoPedidos)
				)
			}
			if (localStorage.curCliente != undefined) {
				this.$set(
					this.$store.state,
					"curCliente",
					JSON.parse(localStorage.curCliente)
				)
			}
			if (localStorage.lastCheck != undefined) {
				this.$set(
					this.$store.state,
					"lastCheck",
					JSON.parse(localStorage.lastCheck)
				)
			}
		}

		if (this.Rota == "/sair") {
			if (
				this.dadosVenda.produtospedido.length > 0 ||
				this.filaPedidos.length > 0 ||
				this.rascunhoPedidos.length > 0
			) {
				this.$vex.dialog.open({
					message:
						"Existem pedidos e rascunhos pendentes de envio, e serão apagados ao sair, deseja mesmo sair?",
					callback: (res) => {
						if (res) {
							localStorage.clear()
							this.$store.state.dadosVenda.produtospedido = []
							this.$store.state.filaPedidos = []
							this.$store.state.rascunhoPedidos = []
							this.$socket.closeConnection()
							this.$router.push("/login")
						} else {
							this.$router.push("/")
						}
					},
				})
			} else {
				localStorage.clear()
				this.$socket.closeConnection()
				this.$router.push("/login")
			}
		}

		if (this.Rota == "/sincronizar") {
			this.sincronizaDados()
		}

		if (this.Rota == "/atualizar") {
			this.$router.push("/")
		}

		if (this.Rota == "/venda" && this.dadosVenda.cliente == undefined) {
			this.$router.push("/")
		}

		if (this.User == undefined && this.Rota != "/login") {
			this.$router.push("/login")
		}
	},

	mounted() {
		let vh = window.innerHeight * 0.01
		document.documentElement.style.setProperty("--vh", `${vh}px`)
		window.addEventListener("resize", this.changeHeight)

		this.time()
	},

	methods: {
		changeHeight() {
			let vh = window.innerHeight * 0.01
			document.documentElement.style.setProperty("--vh", `${vh}px`)
		},

		async time() {
			let self = this
			this.hrAtual = new Date()

			setTimeout(self.time, 1000)
		},

		alternaMenu() {
			this.$store.state.mostraMenu = !this.$store.state.mostraMenu
		},

		sincronizaDados(route) {
			this.$reloadCache()
			this.$router.push(route ? route : "/")
		},
	},

	watch: {
		Cache: {
			deep: true,
			async handler() {
        const json = JSON.stringify(this.Cache)
				// localStorage.setItem("Cache", data)
        // await db.Cache.add({json: data})
        let dbData = await db.Cache.update(1, {json})
        if (dbData === 0) {
          dbData = await db.Cache.add({json})
        }
			},
		},

		Rota(after, before) {
			if (this.Rota == "/sair") {
				if (
					this.dadosVenda.produtospedido.length > 0 ||
					this.filaPedidos.length > 0 ||
					this.rascunhoPedidos.length > 0
				) {
					this.$vex.dialog.open({
						message:
							"Existem pedidos e rascunhos pendentes de envio, e serão apagados ao sair, deseja mesmo sair?",
						callback: (res) => {
							if (res) {
								localStorage.clear()
								this.$store.state.dadosVenda.produtospedido = []
								this.$store.state.filaPedidos = []
								this.$store.state.rascunhoPedidos = []
								this.$socket.closeConnection()
								this.$router.push("/login")
							} else {
								this.$router.push(before)
							}
						},
					})
				} else {
					localStorage.clear()
					this.$socket.closeConnection()
					this.$router.push("/login")
				}
			}

			if (this.Rota == "/sincronizar") {
				if (!this.isOnline) {
					this.$vex.dialog.alert("Não é possível sincronizar em modo offline")
					this.$router.push("/")
				} else {
					this.sincronizaDados(before)
				}
			}

			if (this.Rota == "/atualizar") {
				if (!this.isOnline) {
					this.$vex.dialog.alert("Não é possível atualizar em modo offline")
					this.$router.push("/")
				} else {
					if (
						this.dadosVenda.produtospedido.length > 0 ||
						this.filaPedidos.length > 0 ||
						this.rascunhoPedidos.length > 0
					) {
						this.$vex.dialog.open({
							message:
								"Existem pedidos e rascunhos pendentes de envio, e podem ser perdidos ao atualizar, deseja mesmo atualizar agora?",
							callback: (res) => {
								if (res) {
									document.location.reload(true)
								} else {
									this.$router.push("/")
								}
							},
						})
					} else {
						document.location.reload(true)
					}
				}
			}
		},

		lastCheck: {
			deep: true,
			handler() {
				localStorage.setItem("lastCheck", JSON.stringify(this.lastCheck))
			},
		},
		dadosVenda: {
			deep: true,
			handler() {
				localStorage.setItem("dadosVenda", JSON.stringify(this.dadosVenda))
			},
		},
		curCliente: {
			deep: true,
			handler() {
				localStorage.setItem("curCliente", JSON.stringify(this.curCliente))
			},
		},

		filaPedidos: {
			deep: true,
			handler() {
				localStorage.setItem("filaPedidos", JSON.stringify(this.filaPedidos))
			},
		},

		rascunhoPedidos: {
			deep: true,
			handler() {
				localStorage.setItem(
					"rascunhoPedidos",
					JSON.stringify(this.rascunhoPedidos)
				)
			},
		},

		totalVenda() {
			this.dadosVenda.valor_venda = this.totalVenda
		},

		Online() {
			if (this.Online) {
				if (this.envia_pedido_auto == "true") {
					this.$reloadCache()
				}
			}
		},
	},
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Lato|Roboto&display=swap");
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	// overflow: hidden;
	&::-webkit-scrollbar-track {
		background-color: #f5f5f5;
	}

	&::-webkit-scrollbar {
		width: 8px;
		height: 8px;
		background-color: #f5f5f5;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #7e7e7e;
	}

	font-family: "Roboto";
	overscroll-behavior: none;
}

*:not(input),
*:focus:not(input) {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	outline-style: none; /*IE*/
}

.app {
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	.home-layout-topbar {
		position: fixed;
		top: 0;
		left: 0;
	}

	.home-layout-leftmenu {
		position: fixed;
		top: 45px;
		left: 0;
	}

	.home-layout-usermenu {
		position: fixed;
		top: 0;
		right: 0;
	}

	.home-layout-pagebody {
		background-color: #e9e9e9;
		position: fixed;
		left: 200px;
		top: 45px;
		width: calc(100% - 200px);
		height: calc((var(--vh, 1vh) * 100) - 40px);
		overflow: hidden;
		transition: left 0.6s ease, width 0.6s ease;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.expediente {
		position: fixed;
		top: 50%;
		text-align: center;
		width: 100%;
	}

	.home-layout-showmenu {
		transform: translateX(0) !important;
	}

	@media all and (max-width: 800px) {
		.home-layout-pagebody {
			left: 45px;
			width: calc(100% - 45px);
		}
	}

	@media all and (max-width: 500px) {
		.home-layout-pagebody {
			left: 0;
			width: 100%;
		}
	}
}
</style>
