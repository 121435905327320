<template>
	<div class="venda-carrinho-body">
		<div class="venda-carrinho-table">
			<table class="global-table venda-carrinho-table-table">
				<thead>
					<th width="8%">Cod.</th>
					<th width="60%">Desc.</th>
					<th width="6%">Qnt.</th>
					<th v-if="mostraValor != 'false'">Vl. Un.</th>
					<th v-if="mostraValor != 'false'">Subtotal</th>
					<th width="4%">Del.</th>
				</thead>
				<tbody>
					<tr v-for="(produto, key) in dadosVenda.produtospedido" :key="key">
						<td width="8%">{{ String(produto.cod_est).trim() }}</td>
						<td width="60%">
							<p>
								{{ produto.descricao }}
							</p>
							<img
								class="venda-sel-promoicon"
								v-if="produto.vl_promo > 0"
								src="@/assets/icones/promo.svg"
							/>
						</td>
						<td width="6%">
							<input
								type="number"
								v-model="produto.quant"
								@input="isTyping = true"
							/>
						</td>
						<td align="right" v-if="mostraValor != 'false'">
							{{ vlProduto(produto) }}
						</td>
						<td align="right" v-if="mostraValor != 'false'">
							{{ vlProduto(produto, produto.quant) }}
						</td>
						<td
							width="4%"
							class="venda-carrinho-table-table-trash"
							@click="apagaProduto(key)"
						>
							<img :src="require(`@/assets/icones/lixo.svg`)" alt="icone" />
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="venda-carrinho-addProduto">
			<input type="text" v-model="codigo" placeholder="Código" />
			<input type="number" min="0" v-model="quant" placeholder="Qnt." />
			<button @click="addProdutoCodigo()">+</button>
			<button @click="limpaCarrinho()" class="limpaCarrinho">
				<img src="@/assets/icones/emptycart.png" alt="LimpaCarrinho" />
			</button>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex"
import _ from "lodash"

export default {
	data() {
		return {
			codigo: null,
			quant: null,
			Produtos: [],
			isTyping: false,
		}
	},

	created() {
		this.Cache.Produtos.forEach((grupo) => {
			this.Produtos = this.Produtos.concat(grupo.produtos)
		})

		if (
			this.produtosCliente[0] != undefined &&
			this.produtosCliente[0].produtos.length > 0
		) {
			this.Produtos = this.Produtos.filter((produtoCompleto) =>
				this.produtosCliente[0].produtos.find((prod) => {
					if (prod.id_produto == produtoCompleto.id) {
						produtoCompleto.valor_vend = prod.valor_vend
						return produtoCompleto
					}
				})
			)
		} else {
			if (this.vendeSemProduto == "false") {
				this.Produtos = []
			}
		}
	},
	computed: {
		...mapState(["dadosVenda", "Cache"]),
		vendeSemProduto() {
			return process.env.VUE_APP_VENDE_SEM_PRODUTO
		},
		mostraValor() {
			return process.env.VUE_APP_MOSTRA_VALOR
		},

		produtosCliente() {
			return this.Cache.ProdutosCliente.filter((prod) => {
				return prod.id_cliente == this.dadosVenda.cliente.id
			})
		},
		produtosPedido() {
			return this.dadosVenda.produtospedido
		},
	},
	methods: {
		mudaQuant(e, i) {
			this.$set(this.dadosVenda.produtospedido[i], "quant", e.target.value)
		},

		async apagaProduto(item) {
			await this.$vex.dialog.confirm({
				message: "Deseja mesmo apagar esse produto?",

				callback: async (response) => {
					if (response) {
						await this.dadosVenda.produtospedido.splice(item, 1)
					}
				},
			})
		},

		fatorReal(produto) {
			let fator = produto.fator

			if (this.produtosCliente.length < 0) {
				fator = this.produtosCliente[0].produtos
					.filter((value) => value.id_produto == produto.id)
					.map((value) => value.fator)[0]
			}

			return fator > 0 ? fator : produto.fator
		},

		vlProduto(produto, quant = 0) {
			let valor = produto.valor_vend

			if (produto.vl_promo > 0) {
				valor = produto.vl_promo
			}

			if (quant > 0) {
				valor = valor * quant
			}
			return this.$moeda(valor)
		},

		async addProdutoCodigo() {
			if (this.codigo !== null && this.quant !== null) {
				let produto = await this.Produtos.find(
					(produto) => String(produto.cod_est).trim() == this.codigo
				)

				if (produto) {
					if (this.quant != 0) {
						if (
							this.quant % produto.fator == 0 ||
							this.dadosVenda.tVenda != "V"
						) {
							produto.quant = this.quant

							if (process.env.VUE_APP_MULTI_ITENS == "false") {
								let consulta = this.dadosVenda.produtospedido.findIndex(
									(prod) => prod.id == produto.id
								)
								if (consulta != -1) {
									this.dadosVenda.produtospedido[consulta].quant =
										Number(this.dadosVenda.produtospedido[consulta].quant) +
										Number(produto.quant)
								} else {
									this.dadosVenda.produtospedido.push(
										JSON.parse(JSON.stringify(produto))
									)
								}
							} else {
								this.dadosVenda.produtospedido.push(
									JSON.parse(JSON.stringify(produto))
								)
							}

							this.codigo = null
							this.quant = null
						} else {
							this.$vex.dialog.alert({
								message: `Quantidade ${produto.quant} para o produto ${
									produto.descricao
								} é inválida (Não é múltiplo de ${this.fatorReal(produto)})`,
							})
							this.quant = this.multiploProximo(
								this.fatorReal(produto),
								this.quant,
								false
							)
						}
					} else {
						this.$vex.dialog.alert("Quantidade não pode ser 0")
					}
				} else {
					this.$vex.dialog.alert("Nenhum produto com o código foi encontrado")
				}
			} else {
				this.$vex.dialog.alert(
					"Codigo ou Quantidade não podem estar em branco!"
				)
			}
		},

		limpaCarrinho() {
			this.$vex.dialog.confirm({
				message: "Deseja mesmo limpar os produtos da Venda?",

				callback: (response) => {
					if (response) {
						this.dadosVenda.produtospedido = []
					}
				},
			})
		},

		debounce: _.debounce(($) => {
			$.isTyping = false
		}, 1000),

		multiploProximo(fator, valor, maior = true) {
			let valAtual = valor
			while (valAtual % fator != 0) {
				if (maior) {
					valAtual++
				} else {
					valAtual--
				}
			}
			if (valAtual == 0) {
				return this.multiploProximo(fator, valor)
			}
			return valAtual
		},

		verificaQuant() {
			for (const produto of this.produtosPedido) {
				if (
					Number(produto.quant) % this.fatorReal(produto) != 0 &&
					this.dadosVenda.tVenda == "V"
				) {
					this.$vex.dialog.alert({
						message: `A quantidade do produto ${
							produto.descricao
						} é inválida (Não é múltiplo de ${this.fatorReal(produto)})`,
					})
					produto.quant = this.multiploProximo(
						this.fatorReal(produto),
						produto.quant,
						false
					)
					break
				}
			}
		},
	},
	watch: {
		produtosPedido: {
			deep: true,
			handler() {
				this.debounce(this)
			},
		},
		isTyping() {
			if (!this.isTyping) {
				this.verificaQuant()
			}
		},
	},
}
</script>

<style lang="scss">
.venda-carrinho-body {
	height: 100%;
	.venda-carrinho-table {
		width: 100%;
		height: calc(100% - 240px);
		overflow: auto;

		.venda-carrinho-table-table {
			width: 100%;

			input[type="number"] {
				width: 100%;
				text-align: center;
			}

			p {
				display: inline-block;
			}

			.venda-sel-promoicon {
				height: 20px;
				margin-left: 5px;
				display: inline-flex;
			}

			.venda-carrinho-table-table-trash {
				text-align: center;
				cursor: pointer;
				transition: background-color 0.6s ease;

				img {
					width: 16px;
				}

				&:hover {
					background-color: #a5a5a5;
				}
			}
		}
	}

	.venda-carrinho-addProduto {
		position: absolute;
		display: flex;
		justify-content: center;
		flex-direction: row;
		align-items: center;
		border-top: 2px dashed #ccc;
		margin-top: 15px;
		height: 60px;
		width: 100%;
		bottom: 100px;
		border-bottom: 3px solid #a5a5a5;
		input[type="text"] {
			width: 100px;
			height: 30px;
			border: 1px solid rgb(158, 158, 158);
			border-right: none;
		}
		input[type="number"] {
			width: 40px;
			height: 30px;
			border: 1px solid rgb(158, 158, 158);
			border-right: none;
		}
		button {
			border: none;
			background-color: inherit;
			height: 30px;
			width: 30px;
			border: 1px solid rgb(158, 158, 158);
			cursor: pointer;

			&:hover {
				background-color: #ccc;
			}
		}
		.limpaCarrinho {
			position: absolute;
			right: 10px;
			border: none;
			img {
				width: 100%;
				height: 100%;
			}
		}
	}
}
</style>
